import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { textFieldStyle } from './style';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { IDDL } from './interface/Student';
import { IClass } from './interface/Class';
import { IRegistration } from './interface/Registration';
import { getSessions } from './Common';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export enum RegistrationControlEnum {
    REGI_NO,
    STUDENT_ID,
    CLASS_ID,
    ROLL_NO,
    FEE,
    SESSION,
    IS_FEE,
    REMARK_FOR_FEE,
    REGI_FEE
}


interface IRegistrationProps {
    students: Array<IDDL>,
    classes: Array<IClass>,
    isSuccess: boolean,
    regiId?: number,
    loadStudent: () => void,
    onRegiStudent: (regiStudent: IRegistration) => void
}

export const RegistrationForm = ({ students, classes, isSuccess, regiId, loadStudent, onRegiStudent }: IRegistrationProps): React.ReactElement => {
    const sessions = getSessions();

    const [regiNoState, setRegiNoState] = useState<string>('');
    const [studentState, setStudentState] = useState<IDDL>();
    const [classState, setClassState] = useState<string>('');
    const [rollNoState, setRollNoState] = useState<string>('');
    const [feeState, setFeeState] = useState<string>('');
    const [sessionState, setSessionState] = useState<string>('');
    const [isFreeState, setIsFreeState] = useState([true, false]);
    const [regiFeeState, setRegiFeeState] = useState('');
    const [remarkForFreeState, setRemarkForFreeState] = useState<string>('');
    const [isInvalidState, setIsInvalidState] = useState<RegistrationControlEnum>();
    
    useEffect(() => {
        students.length <= 0 && loadStudent();
    }, []);

    useEffect(() => {
        if (regiId) {
            resetState();
            const receiptUrl = `https://fee.krinstituteagra.com/regireceipt.html?id=${regiId}`;
            //const receiptUrl = `http://localhost:3000/regireceipt.html?id=${regiId}`;
            window.open(receiptUrl, "Google", "width=720,height=500");
        }
    }, [isSuccess])

    const resetState = () => {
        setRegiNoState('');
        setStudentState({} as IDDL);
        setClassState('');
        setRollNoState('');
        setFeeState('');
        setSessionState('');
        setIsFreeState([false, false]);
        setRemarkForFreeState('');
        setRegiFeeState('');
    }
    const onInputChange = (val: any, input:RegistrationControlEnum) => {
        
        switch(input){
            case RegistrationControlEnum.REGI_NO:
                setRegiNoState(val);
                break;
            case RegistrationControlEnum.STUDENT_ID:
                setStudentState(val);
                break;
            case RegistrationControlEnum.CLASS_ID:
                setClassState(val);
                break;
            case RegistrationControlEnum.ROLL_NO:
                if (val.match(/^\d+$/) || val == '') {
                    setRollNoState(val);
                }
                break;
            case RegistrationControlEnum.FEE:
                if (val.match(/^\d+$/) || val == '') {
                    setFeeState(val);
                }
                break;
            case RegistrationControlEnum.SESSION:
                setSessionState(val);
                break;
            case RegistrationControlEnum.REMARK_FOR_FEE:
                setRemarkForFreeState(val);
                break;
            case RegistrationControlEnum.REGI_FEE:
                if (val.match(/^\d+$/) || val == '') {
                    setRegiFeeState(val);
                }
                break;
        }
    }
    const validate = () => {
        if (!regiNoState.trim()) {
            setIsInvalidState(RegistrationControlEnum.REGI_NO);
            return false;
        }
        if ((studentState?.id ?? 0) <= 0 || !studentState?.label.trim()){
            setIsInvalidState(RegistrationControlEnum.STUDENT_ID);
            return false;
        }
        if(!classState.toString().trim()){
            setIsInvalidState(RegistrationControlEnum.CLASS_ID);
            return false;
        }
        if(!rollNoState.trim()){
            setIsInvalidState(RegistrationControlEnum.ROLL_NO);
            return false;
        }
        if(!regiFeeState.trim()){
            setIsInvalidState(RegistrationControlEnum.REGI_FEE);
            return false;
        }
        if(!feeState.trim()){
            setIsInvalidState(RegistrationControlEnum.FEE);
            return false;
        }
        if(!sessionState.trim()){
            setIsInvalidState(RegistrationControlEnum.SESSION);
            return false;
        }
        if(isFreeState[0] && isFreeState[1]){
            if(!remarkForFreeState.trim()){
                setIsInvalidState(RegistrationControlEnum.REMARK_FOR_FEE);
                return false;
            }
        }
        return true;
    }
    const onSubmit = () => {
        debugger
        if (validate()){
            const regi: IRegistration = {
                id: 0,
                classId: parseInt(classState),
                regiNo: regiNoState,
                rollNo: parseInt(rollNoState),
                session: sessionState,
                studentId: studentState?.id ?? 0,
                totalFee: parseInt(feeState),
                isFree: isFreeState[0] && isFreeState[1],
                remark: remarkForFreeState,
                extraFee: 0,
                regiFee: parseInt(regiFeeState)
            };
            onRegiStudent(regi);
        }
    }
    const handleIsFree = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setIsFreeState([event.target.checked, event.target.checked]);
    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState === RegistrationControlEnum.REGI_NO}
                            id="regi_no" label="Regi. No." value={regiNoState}
                            onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.REGI_NO)}}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <Autocomplete
                            disablePortal
                            id="select_student"
                            options={students}
                            fullWidth
                            value={studentState}
                            renderInput={(params) => <TextField {...params} label="Student Name" />}
                            onChange = {(e, val)=> {onInputChange(val, RegistrationControlEnum.STUDENT_ID)}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="cla
                            ss-label">Class</InputLabel>
                            <Select
                                labelId="class-label"
                                id="class_select"
                                value={classState}
                                label="Age"
                                error={isInvalidState === RegistrationControlEnum.CLASS_ID}
                                onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.CLASS_ID)}}
                            >
                                {classes.map(cls => (
                                    <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="roll_no" label="Roll No." value={rollNoState}
                            error={isInvalidState === RegistrationControlEnum.ROLL_NO}
                            onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.ROLL_NO)}}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="regi_fee" label="Reg. Fee" value={regiFeeState}
                            error={isInvalidState === RegistrationControlEnum.REGI_FEE}
                            onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.REGI_FEE)}}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="total_fee" label="Total Fee" value={feeState}
                            error={isInvalidState === RegistrationControlEnum.FEE}
                            onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.FEE)}}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="session-label">Session</InputLabel>
                            <Select
                                labelId="session-label"
                                id="session_select"
                                value={sessionState}
                                label="Age"
                                error={isInvalidState === RegistrationControlEnum.SESSION}
                                onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.SESSION)}}
                            >
                                {sessions.map((session) => (
                                    <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControlLabel
                            label="Is Free"
                            control={
                                <Checkbox
                                    checked={isFreeState[0] && isFreeState[1] }
                                    
                                    onChange={(e)=>{handleIsFree(e)}}
                                />
                            }
                        />
                        

                    </Grid>
                    <Grid item xs={12} md={5} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="remarkForFee" label="Remark for free" value={remarkForFreeState}
                            error={isInvalidState === RegistrationControlEnum.REMARK_FOR_FEE}
                            onChange = {(e)=> {onInputChange(e.target.value, RegistrationControlEnum.REMARK_FOR_FEE)}}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                        <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={onSubmit}
                            >
                                Register
                            </LoadingButton>
                            <Button variant="outlined" color="secondary" onClick={resetState}>Cancel</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
import { ConnectedDashboardLayout } from "./connected/ConnectedDashboardLayout";
import { ConnectedSignIn } from "./connected/ConnectedSingin";
import { IIdentity } from "./state/IdentityState";

interface IAuthContainerProps {
    identity?: IIdentity
}
export const AuthContainer = ({identity}: IAuthContainerProps): React.ReactElement => {
    return (
        <>
            {identity ? (<ConnectedDashboardLayout/>) : (<ConnectedSignIn/>)}
        </>
    );
}
import { connect } from "react-redux";
import { clearFeeStateAction, feeReceiveAction, getFeeReceiveDetailAction, getFeeReceiveDetailByStudentAction } from "../action/FeeAction";
import { getRegiStudentsAction } from "../action/RegistrationAction";
import { IClass } from "../interface/Class";
import { IFee, IFeeReceive } from "../interface/Fee";
import { IDDL } from "../interface/Student";
import { ReceiveFee } from "../ReceiveFee";
import { classSelector } from "../selectors/ClassSelector";
import { errorSelector } from "../selectors/ErrorSelector";
import { feeReceiveDetailSelector } from "../selectors/FeeSelector";
import { regiStudentDDLSelector, studentsDDLSelector } from "../selectors/StudentSelector";
import {successMessageSelector, successRecordIdSelector} from "../selectors/SuccessMsgSelector";
import { IInitialState } from "../state/InitialState";

interface IStateProps{
    classes: Array<IClass>,
    feeDetail?: IFeeReceive,
    succeededFeeId?: number,
    isError: boolean,
    students: Array<IDDL>
}

interface IDispatchProps{
    getFeeDetailByStudent: (classId: number, studentId: number, session: string) => void,
    getFeeDetail: (classId: number, rollNo: number, session: string) => void,
    onFeeReceive: (fee: IFee) => void,
    clearFeeState: () => void,
    loadStudent: (session:string) => void
}

const mapStateToProps = (state:IInitialState): IStateProps => {
    return {
        classes: classSelector(state),
        feeDetail: feeReceiveDetailSelector(state),
        succeededFeeId: successRecordIdSelector(state),
        isError: !!errorSelector(state),
        students: regiStudentDDLSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        getFeeDetail: (classId: number, rollNo: number, session: string) => {
            dispatch(getFeeReceiveDetailAction(classId, rollNo, session));
        },
        getFeeDetailByStudent: (classId: number, studentId: number, session: string) => {
            dispatch(getFeeReceiveDetailByStudentAction(classId, studentId, session));
        },
        onFeeReceive: (fee: IFee) => {
            dispatch(feeReceiveAction(fee));
        },
        clearFeeState: () => {
            dispatch(clearFeeStateAction());
        },
        loadStudent: (session: string) => {
            dispatch(getRegiStudentsAction(session, false));
        }
    }
}

export const ConnectedReceiveFee = connect(mapStateToProps, mapDispatchToProps)(ReceiveFee);
import { connect } from "react-redux";
import { regiFeeReportAction } from "../action/RegistrationAction";
import { IClass } from "../interface/Class";
import { IRegiStudentDetails } from "../interface/Student";
import { RegiFeeReport } from "../RegiFeeReport";
import { classSelector } from "../selectors/ClassSelector";
import { getRegiFeeReportSelector } from "../selectors/RegistrationSelector";
import { IInitialState } from "../state/InitialState";

interface IStateProps {
    classes: Array<IClass>,
    regiFees: Array<IRegiStudentDetails>
}

interface IDispatchProps {
    onSearch : (session: string, classId: number) => void
}

const mapStateToProps = (state: IInitialState) : IStateProps => {
    return {
        classes: classSelector(state),
        regiFees: getRegiFeeReportSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onSearch: (session: string, classId: number) =>{
            dispatch(regiFeeReportAction(session, classId));
        }
    }
}


export const ConnectedRegiFeeReport = connect(mapStateToProps, mapDispatchToProps)(RegiFeeReport);
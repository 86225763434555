import React from "react"
import { Alert, AlertColor } from "@mui/material";
import './App.css';

interface IAlertComponent {
    alertType: AlertColor,
    msg?: string,
    onCloseAlert: () => void
}
const alertStyle = {
    position: "fixed",
    right: '40px',
    zIndex: '9999',
    backgroundColor: 'rgb(237, 247, 237)'
}


export const AlertComponent = ({ alertType, msg, onCloseAlert }: IAlertComponent): React.ReactElement => {
    React.useEffect(() => {
        if (msg) {
            setTimeout(() => {
                onCloseAlert();
            }, 4000);
        }
    });

    const onCloseClick = ()=>{
        onCloseAlert();
    }
    return (
        <>
            {msg && <Alert variant="outlined" severity={alertType} onClose={()=> {onCloseClick()}} className="alert-style">{msg}</Alert>}
        </>
    );
}
import { connect } from "react-redux";
import { changePasswordAction } from "../action/UpdateIdentityAction"
import { ChangePassword } from "../ChangePassword";

interface IDispatchProps {
    onChangePassword: (currentPassword: string, newPassword: string) => void
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onChangePassword: (currentPassword: string, newPassword: string) => {
            dispatch(changePasswordAction(currentPassword, newPassword));
        }
    }
}

export const ConnectedChangePassword = connect(null, mapDispatchToProps)(ChangePassword);
export const digitFormat = (num: number): string => {
    return num.toLocaleString('en-US', {minimumIntegerDigits:2, useGrouping:false});
}

export const dateToString = (dt: Date): string => {
    return `${digitFormat(dt.getMonth()+1)}/${digitFormat(dt.getDate())}/${dt.getFullYear()}`;
}

export const getSessions = (): Array<string> => {
    const sessions: Array<string> = [];
    const dt = new Date();
    for (var year = dt.getFullYear() - 6; year <= dt.getFullYear() + 4; year++) {
        sessions.push(`${year}-${year + 1}`);
    }
    return sessions;
}

export const getCurrentSession = ():string => {
    const dt = new Date();
    if(dt.getMonth()+1 > 3){
        return `${dt.getFullYear()}-${dt.getFullYear()+1}`;
    }
    else{
        return `${dt.getFullYear()-1}-${dt.getFullYear()}`;
    }
}

export const dateFormate = (dt: Date)=> {
    return `${digitFormat(dt.getDate())}-${digitFormat(dt.getMonth()+1)}-${dt.getFullYear()}`;
}
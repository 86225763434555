import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputUnstyled from '@mui/base/InputUnstyled';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { getCurrentSession, getSessions } from "./Common";
import { IRegiStudentDetails } from "./interface/Student";
import { IRegistration } from "./interface/Registration";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DialogBox } from "./Dialog";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from '@mui/material/Stack';
import { IClass } from "./interface/Class";
import { EnumMenuItem, MenuDropDown } from "./MenuDropdown";
import { UpdateRegiView } from "./UpdateRegi";

const FEE = 'Fee';
const CLASS = 'CLASS';
const ROLL_NO = 'RollNo';
interface IRegiStudentsProps {
    regiStudents: Array<IRegiStudentDetails>,
    isLoading: boolean,
    classes: Array<IClass>,
    onGetRegiStudents: (session: string, selectLeft: boolean) => void,
    onPromoteStudent: (regi: IRegistration) => void,
    onUpdateStudent: (regi: IRegistration) => void,
    onUpdateStudentFee: (regi: IRegistration) => void,
    onMarkLeft: (regiId: number, session:string, isLeft: boolean) => void
}

interface IPromoteViewProps {
    classes: Array<IClass>,
    onPromot: (totalFee: number, isFree: boolean, classId: number, rollNo: number, remark?: string) => void,
    onCancel: () => void
}
interface IEditViewProps {
    regiStudent: IRegiStudentDetails,
    onUpdate: (extraFee: number, isFree: boolean, remark: string) => void,
    onCancel: () => void
}

export const EditView = ({ regiStudent, onUpdate, onCancel }: IEditViewProps): React.ReactElement => {
    const [extraFeeState, setExtraFeeState] = useState<string>(regiStudent.extraFee.toString());
    const [remarkState, setRemarkState] = useState<string>(regiStudent.remark);
    const [isFreeState, setIsFreeState] = useState(regiStudent.isFree);

    const handleIsFree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFreeState(event.target.checked);
    }
    const onInputChange = (val: any, input: string) => {
        switch (input) {
            case 'ExtraFee':
                const strs = val.split('-');
                let min = '';
                if (strs.length == 2 && strs[0].length == 0) {
                    min = '-';
                    val = strs[1]
                }
                if (val.match(/^\d+$/) || val == '') {
                    setExtraFeeState(min + val);
                }
                break;
            case 'Remark':
                setRemarkState(val);
                break;
        }
    }

    const onUpdateClick = () => {
        onUpdate(parseInt(extraFeeState), isFreeState, remarkState);
    }
    return (
        <>
            <TextField
                autoFocus
                id="txt_fee"
                margin="dense"
                required={true}
                label="Add/Less Fee"
                type="text"
                size="small"
                variant="outlined"
                value={extraFeeState}
                onChange={(e) => { onInputChange(e.target.value, 'ExtraFee') }}
            />
            <FormControlLabel style={{ marginLeft: '10px' }}
                label="Is Free"
                control={
                    <Checkbox
                        checked={isFreeState}

                        onChange={(e) => { handleIsFree(e) }}
                    />
                }
            />
            <TextField
                margin="dense"
                id="remark"
                label="Remark"
                type="text"
                fullWidth
                variant="outlined"
                value={remarkState}
                onChange={(e) => { onInputChange(e.target.value, 'Remark') }}
            />
            <Grid container>
                <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                        <Button variant="outlined" color="secondary" onClick={onUpdateClick}>Update</Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}



export const PromoteView = ({ classes, onPromot, onCancel }: IPromoteViewProps): React.ReactElement => {
    const [totalFeeState, setTotalFeeState] = useState<string>('');
    const [rollNoState, setRollNoState] = useState<string>('');
    const [isFreeState, setIsFreeState] = useState([true, false]);
    const [remarkState, setRemarkState] = useState<string>('');
    const [classState, setClassState] = useState<string>('');
    const [validationState, setValidationState] = useState<{ field: string, isError: boolean }>({ field: '', isError: false });
    const handleIsFree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFreeState([event.target.checked, event.target.checked]);
    }
    const onInputChange = (val: any, input: string) => {
        switch (input) {
            case FEE:
                if (val.match(/^\d+$/) || val == '') {
                    setTotalFeeState(val);
                }
                break;
            case ROLL_NO:
                if (val.match(/^\d+$/) || val == '') {
                    setRollNoState(val);
                }
                break;
            case 'Remark':
                setRemarkState(val);
                break;
            case CLASS:
                setClassState(val);
                break;
        }
    }

    const onPromotClick = () => {
        debugger
        if (!totalFeeState) {
            setValidationState({ field: FEE, isError: true });
        }
        else if (!classState) {
            setValidationState({ field: CLASS, isError: true });
        }
        else if (!rollNoState) {
            setValidationState({ field: ROLL_NO, isError: true });
        }
        else {
            setValidationState({ field: '', isError: false });
            onPromot(parseInt(totalFeeState), (isFreeState[0] && isFreeState[1]), parseInt(classState), parseInt(rollNoState), remarkState);
        }
    }
    return (
        <>
            <TextField
                autoFocus
                id="txt_fee"
                margin="dense"
                required={true}
                label="Total Fee"
                type="text"
                size="small"
                variant="outlined"
                value={totalFeeState}
                error={validationState.field == FEE && validationState.isError}
                onChange={(e) => { onInputChange(e.target.value, FEE) }}
            />
            <FormControlLabel style={{ marginLeft: '10px' }}
                label="Is Free"
                control={
                    <Checkbox
                        checked={isFreeState[0] && isFreeState[1]}

                        onChange={(e) => { handleIsFree(e) }}
                    />
                }
            />
            <FormControl size="small" margin="dense" style={{ width: '150px', marginRight: '10px' }}>
                <InputLabel id="class-label">Class</InputLabel>
                <Select
                    labelId="class-label"
                    id="class_select"
                    value={classState}
                    label="Class"
                    required={true}
                    error={validationState.field == CLASS && validationState.isError}
                    onChange={(e) => { onInputChange(e.target.value, CLASS) }}
                >
                    {classes.map(cls => (
                        <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                    ))}

                </Select>
            </FormControl>
            <TextField
                margin="dense"
                id="remark"
                label="Roll Number"
                type="text"
                size="small"
                variant="outlined"
                required={true}
                error={validationState.field == ROLL_NO && validationState.isError}
                value={rollNoState}
                onChange={(e) => { onInputChange(e.target.value, ROLL_NO) }}
            />
            <TextField
                margin="dense"
                id="remark"
                label="Remark"
                type="text"
                fullWidth
                variant="outlined"
                value={remarkState}
                onChange={(e) => { onInputChange(e.target.value, 'Remark') }}
            />
            <Grid container>
                <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                        <Button variant="outlined" color="secondary" onClick={onPromotClick}>Update</Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}
export const RegiStudents = ({ classes, regiStudents, isLoading,
    onGetRegiStudents, onPromoteStudent, onUpdateStudent,onUpdateStudentFee, onMarkLeft }: IRegiStudentsProps): React.ReactElement => {
    const sessions = getSessions();
    const currentSession = getCurrentSession();
    const [sessionState, setSessionState] = useState<string>(currentSession);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [currentStudentState, setCurrentStudentState] = useState<IRegiStudentDetails>({} as IRegiStudentDetails);
    const [editModeState, seteditModeState] = useState<EnumMenuItem>();
    const [statusState, setStatusState] = useState<number>(0);
    useEffect(() => {
        onGetRegiStudents(getCurrentSession(), false);
    }, [])

    useEffect(() => {
        console.log('IsLoading=>', isLoading);
        setIsDialogOpen(false);
    }, [isLoading]);

    const onEditClick = (editMod: EnumMenuItem, row: IRegiStudentDetails) => {
        setCurrentStudentState(row);
        seteditModeState(editMod);
        setIsDialogOpen(true);
    }
    const onPromoteClick = (row: IRegiStudentDetails) => {
        setCurrentStudentState(row);
        seteditModeState(undefined);
        setIsDialogOpen(true);
    }

    const onMarkLeftClick = (row: IRegiStudentDetails, isLeft: boolean) => {
        onMarkLeft(row.regiId, row.session, isLeft);
    }
    const columns: GridColDef[] = [
        { field: 'regiNo', headerName: 'Regi. No.', width: 70 },
        { field: 'rollNo', headerName: 'Roll No.', width: 70 },
        { field: 'studentName', headerName: 'Name', width: 150 },
        { field: 'fatherName', headerName: "Father's Name", width: 150 },
        { field: 'session', headerName: 'Session', width: 100 },
        { field: 'className', headerName: 'Class', width: 100 },
        { field: 'mobileNo', headerName: 'Mobile', width: 100 },
        {
            field: 'promote', headerName: 'Action', width: 250, renderCell: (cellValue) => {
                return (
                    <>
                        {/* <Button variant="outlined" size="small" onClick={() => onEditClick(cellValue.row)} startIcon={<EditIcon />}>Edit</Button> <span style={{ width: '5px' }}></span>
                        <Button variant="outlined" size="small" onClick={() => onPromoteClick(cellValue.row)} startIcon={<NavigateNextIcon />}>Promote</Button>
                        <Button variant="outlined" size="small" onClick={() => onPromoteClick(cellValue.row)} startIcon={<NavigateNextIcon />}>Left</Button> */}
                        {cellValue.row.isLeft && (<Button variant="outlined" size="small" onClick={() => onMarkLeftClick(cellValue.row, false)} startIcon={<PublishedWithChangesIcon />}>Active</Button>)}
                        {!cellValue.row.isLeft && (<MenuDropDown onEdit={(editMode) => onEditClick(editMode, cellValue.row)}
                            onPromote={() => onPromoteClick(cellValue.row)}
                            onMarkLeft={() => onMarkLeftClick(cellValue.row, true)} />)}
                    </>
                );
            }
        }
    ];
    const onPromot = (totalFee: number, isFree: boolean, classId: number, rollNo: number, remark?: string) => {
        debugger
        if (currentStudentState) {
            const regiStudent: IRegistration = {
                id: currentStudentState.regiId,
                classId: classId,
                regiNo: currentStudentState.regiNo,
                rollNo: rollNo,
                session: currentStudentState.session,
                studentId: currentStudentState.studentId,
                totalFee: totalFee,
                isFree: isFree,
                extraFee: 0,
                remark: remark ?? '',
                regiFee: 0
            }
            onPromoteStudent(regiStudent);
        }
    }


    const onSessionChange = (session: string) => {
        setSessionState(session);
        onGetRegiStudents(session, statusState!==0);
    }
    const onStatusChange = (status: string)=> {
        setStatusState(parseInt(status));
        onGetRegiStudents(sessionState, parseInt(status)!==0);
    }
    const onDialogClose = () => {
        setIsDialogOpen(false)
    }
    const onUpdateStudentFeeClick = (extraFee: number, isFree: boolean, remark: string) => {
        if (currentStudentState) {
            const regiStudent: IRegistration = {
                id: currentStudentState.regiId,
                classId: 0,
                regiNo: currentStudentState.regiNo,
                rollNo: currentStudentState.rollNo,
                session: currentStudentState.session,
                studentId: currentStudentState.studentId,
                totalFee: 0,
                isFree: isFree,
                extraFee: extraFee,
                remark: remark ?? '',
                regiFee: 0

            }
            onUpdateStudentFee(regiStudent);
        }
    }

    const getViewForPopup = () => {
        if (editModeState){
            return editModeState === EnumMenuItem.EDIT ? (<UpdateRegiView regiStudent={currentStudentState} onCancel={onDialogClose} onUpdate={onUpdateStudent} classes={classes}/>) : 
            (<EditView regiStudent={currentStudentState} onCancel={onDialogClose} onUpdate={onUpdateStudentFeeClick} />);
        }
        else{
            return (<PromoteView classes={classes} onCancel={onDialogClose} onPromot={onPromot}></PromoteView>);
        }

    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
        
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="session-label">Session</InputLabel>
                            <Select
                                labelId="session-label"
                                id="session_select"
                                value={sessionState}
                                label="Age"
                                onChange={(e) => { onSessionChange(e.target.value) }}
                            >
                                {sessions.map((session) => (
                                    <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status_select"
                                value={statusState}
                                label="Status"
                                onChange={(e) => { onStatusChange(e.target.value.toString()) }}
                            >
                                <MenuItem value={1}>Left</MenuItem>
                                <MenuItem value={0}>Active</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(r) => r.regiId}
                            rows={regiStudents}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <DialogBox isOpen={isDialogOpen} 
            title={editModeState ? `Update ${currentStudentState?.studentName} ${editModeState === EnumMenuItem.EDIT ? 'Registration': 'Fee'}` 
            : `Promote ${currentStudentState?.studentName}`}>

                {getViewForPopup()}
            </DialogBox>

        </Grid>
    );
}
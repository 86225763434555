import { IStudent } from "../interface/Student";
import { IBaseActoin } from "./BaseAction";

export const STUDENT_ADD_ACTION = 'STUDENT_ADD_ACTION';
export const STUDENT_UPDATE_ACTION = 'STUDETN_UPDATE_ACTION'; 
export const STUDENT_STATE_UPDATE_ACTION = 'STUDENT_STATE_UPDATE_ACTION';
export const STUDENT_GET_ACTION = 'STUDENT_GET_ACTION'; 
export const STUDENT_GET_SUCCESS = 'STUDENT_GET_SUCCESS';
export const STUDENT_EDIT_ACTION = 'STUDENT_EDIT_ACTION';
export const STUDENT_READONLY_ACTION = 'STUDENT_READONLY_ACTION';
export const RESET_STUDENT_FLAG_ACTION = 'RESET_STUDENT_FLAG_ACTION';

export interface IStudentAddAction extends IBaseActoin<{student: IStudent}>{};
export interface IStudentGetAction extends IBaseActoin{};
export interface IResetStudentFlagAction extends IBaseActoin{};
export interface IStudentGetSuccessAction extends IBaseActoin<{students: Array<IStudent>}>{};

export const studentAddAction = (student: IStudent): IStudentAddAction => {
    return {
        type: STUDENT_ADD_ACTION,
        payload: {student}
    }
}

export const studentUpdateAction = (student: IStudent): IStudentAddAction => {
    return {
        type: STUDENT_UPDATE_ACTION,
        payload: {student}
    }
}

export const studentStateUpdateAction = (student: IStudent): IStudentAddAction => {
    return {
        type: STUDENT_STATE_UPDATE_ACTION,
        payload: {student}
    }
}

export const studentGetAction = (): IStudentGetAction => {
    return {
        type: STUDENT_GET_ACTION,
        payload: {}
    }
}

export const studentGetSuccess = (students: Array<IStudent>): IStudentGetSuccessAction => {
    return {
        type: STUDENT_GET_SUCCESS,
        payload: {students}
    }
}

export const editStudentAction = (student: IStudent): IStudentAddAction => {
    return{
        type: STUDENT_EDIT_ACTION,
        payload: {student}
    }
}

export const readOnlyStudentAction = (student: IStudent): IStudentAddAction => {
    return {
        type: STUDENT_READONLY_ACTION,
        payload: {student}
    }
}

export const resetStudentFlagsAction = ():IResetStudentFlagAction => {
    return {
        type: RESET_STUDENT_FLAG_ACTION,
        payload: {}
    }
} 
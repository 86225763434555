import { connect } from "react-redux";
import { AuthContainer } from "../AuthContainer";
import { identitySelector } from "../selectors/IdentitySelector";
import { IIdentity } from "../state/IdentityState";
import { IInitialState } from "../state/InitialState";

interface IStateProps{
    identity?: IIdentity
}

const mapStateToProps = (state: IInitialState): IStateProps => {
    return {
        identity: identitySelector(state)
    }
}

export const ConnectedAuthContainer = connect(mapStateToProps)(AuthContainer);
import  React, {useState, useEffect} from 'react';
import { Button, Grid, Paper, Stack, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'; 
import EditIcon from '@mui/icons-material/Edit';
import {textFieldStyle, borderStyle, borderBottomStyle} from  './style';
import { IClass } from './interface/Class';

interface IClassesProps {
    classList: Array<IClass>,
    onAddClass: (cls: IClass) => void,
    onGetClass: () => void,
    isAddOrUpdateSuccess: boolean
}

export const Classes = ({classList, onAddClass, onGetClass, isAddOrUpdateSuccess}: IClassesProps): React.ReactElement => {
    const [classState, setClassState] = useState<IClass>();
    const [isEditModeState, setIsEditModeState] = useState<number>(0);
    const [isValidateError, setIsValidationError] = useState<number>(0);
    const [classNameState, setClassNameState] = useState<string>();
    useEffect(()=>{
        !classList && onGetClass();
    }, []);

    useEffect(()=> {
        onCancelClick();
    }, [isAddOrUpdateSuccess]);

    const onEditClick = (cls: IClass) =>{
        setClassState(cls);
        setClassNameState(cls.name);
        setIsEditModeState(1);
    }

    const onCancelClick = () =>{
        setClassState(undefined);
        setClassNameState(undefined);
        setIsEditModeState(0);
        setIsValidationError(0);
    }

    const onAddClick = () => {
        setClassState({id: 0} as IClass);
    }
    
    const onUpdateClick = () => {
        if(classNameState && classNameState.length > 0){
            const id = isEditModeState == 1 ? classState?.id ?? 0 : 0;
            const createdDate = classState?.createdDate;
            const cls:IClass = {id : id, name: classNameState, createdDate: createdDate }
            console.log('onUpdate',cls)
            onAddClass(cls);
        }else{
            setIsValidationError(1);
        }
    }

    const onClassChange = (name : string) =>{
        setIsValidationError(name ? 0 : 1);
        setClassNameState(name);
    }
    const classForm = (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
                <TextField error={isValidateError == 1} required={true}
                    id="outlined-basic" label="Class Name" value={classNameState}
                    onChange={(e) => onClassChange(e.target.value)}
                    variant="outlined" style={textFieldStyle} />
            </Grid>
            <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 10px" }}>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => onUpdateClick()}>{isEditModeState == 1 ? 'Update Class' : 'Add Class'}</Button>
                    <Button variant="outlined" color="secondary" onClick={() => onCancelClick()}>Cancel</Button>
                </Stack>
            </Grid>

        </Grid>
    );

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}><h3> Class Manager</h3></Grid>
                    <Grid item xs={12} md={6}  style={{ display: "flex", justifyContent: "flex-end", padding: "12px 0px" }}>
                     {isEditModeState == 0 && (<Button variant="contained" startIcon={<AddIcon />} onClick={() => onAddClick()}>
                        Add Class
                    </Button>)}
                    </Grid>
                </Grid>
                
                {classState && classForm}
                <br></br>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    
                    <Grid item xs={12} >
                        <Grid container style={borderStyle}>
                        <Grid item xs={3}>Id</Grid>
                        <Grid item xs={6}>Class Name</Grid>
                        <Grid item xs={3}>Action</Grid>
                        </Grid>
                    </Grid>
                    {classList.map((cls) => (
                        <Grid item xs={12} key={'class_'+cls.id}>
                            <Grid container style={borderBottomStyle}>
                                <Grid item xs={3}>{cls.id}</Grid>
                                <Grid item xs={6}>{cls.name}</Grid>
                                <Grid item xs={3}>
                                    <EditIcon onClick={()=> onEditClick(cls)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    
                    { classList.length == 0 && 
                        (<Grid item xs={12} key={'class_no_record'}>
                            <Grid container style={borderBottomStyle}>
                                <Grid item xs={12}>No record found!</Grid>
                            </Grid>
                        </Grid>)
                    }
                </Grid>
            </Paper>
        </Grid>
    )
}
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface IDialogBoxProps {
    isOpen: boolean,
    title: string,
    children?: JSX.Element,
}
export const DialogBox = (props: IDialogBoxProps): React.ReactElement => {
    return (
        <Dialog open={props.isOpen} maxWidth="md" >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.onClose}>{props.cancelText}</Button>
                <Button onClick={props.onOk}>{props.okText}</Button> */}
            </DialogActions>
        </Dialog>
    );
}
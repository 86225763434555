import { IFee, IFeeReceipt, IFeeReceive, IFeeReport } from "../interface/Fee";
import { IBaseActoin } from "./BaseAction";

export const GET_FEE_RECEIVE_DETAIL_ACTION = 'FEE_RECEIVE_DETAIL_ACTION';
export const GET_FEE_RECEIVE_DETAIL_BY_STUDENT_ACTION = 'GET_FEE_RECEIVE_DETAIL_BY_STUDENT_ACTION';
export const GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION = 'GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION';
export const FEE_RECEIVE_ACTION = 'RECEIVE_FEE_ACTION';
export const CLEAR_FEE_STATE_ACTION = 'CLEAR_FEE_STATE_ACTION';
export const FEE_RECEIPT_REPORT_BY_DATE_ACTION = 'FEE_RECEIPT_REPORT_BY_DATE_ACTION'; 
export const FEE_RECEIPT_REPORT_SUCCESS_ACTION = 'FEE_RECEIPT_REPORT_SUCCESS_ACTION'; 
export const FEE_DUE_REPORT_ACTION = 'FEE_DUE_REPORT_ACTION';
export const FEE_DUE_REPORT_SUCCESS_ACTION = 'FEE_DUE_REPORT_SUCCESS_ACTION';

export interface IGetFeeReceiveDetailAction extends IBaseActoin<{classId: number, rollNo: number, session: string}>{};
export interface IGetFeeReceiveDetailByStudentAction extends IBaseActoin<{classId: number, studentId: number, session: string}>{};
export interface IGetFeeReceiveDetailSuccessAction extends IBaseActoin<{feeReceiveDetail: IFeeReceive}>{};
export interface IFeeReceiveAction extends IBaseActoin<{feeReceive: IFee}>{};
export interface IClearFeeStateAction extends IBaseActoin<{}>{};
export interface IFeeReceiptReportByDateAction extends IBaseActoin<{datefrom: string, dateto:string}>{};
export interface IFeeReceiptReportSuccessAction extends IBaseActoin<{feeReceipts: Array<IFeeReceipt>}>{};
export interface IFeeDueReportAction extends IBaseActoin<{session: string, classId: number, selectLeft: boolean}>{};
export interface IFeeDueReportSuccessAction extends IBaseActoin<{dueFees: Array<IFeeReport>}>{};

export const getFeeReceiveDetailAction = (classId: number, rollNo: number, session: string): IGetFeeReceiveDetailAction => {
    return {
        type: GET_FEE_RECEIVE_DETAIL_ACTION,
        payload: {classId, rollNo, session}
    }
}

export const getFeeReceiveDetailByStudentAction = (classId: number, studentId: number, session: string): IGetFeeReceiveDetailByStudentAction => {
    return {
        type: GET_FEE_RECEIVE_DETAIL_BY_STUDENT_ACTION,
        payload: {classId, studentId, session}
    }
}

export const getFeeReceiveDetailSuccessAction = (feeReceiveDetail: IFeeReceive): IGetFeeReceiveDetailSuccessAction =>{
    return {
        type: GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION,
        payload: {feeReceiveDetail}
    }
}

export const feeReceiveAction = (feeReceive: IFee): IFeeReceiveAction => {
    return {
        type: FEE_RECEIVE_ACTION,
        payload: {feeReceive}
    }
}

export const clearFeeStateAction = (): IClearFeeStateAction => {
    return {
        type: CLEAR_FEE_STATE_ACTION,
        payload: {}
    }
}

export const getFeeReceiptsReportByDateAction = (datefrom: string, dateto: string):IFeeReceiptReportByDateAction =>{
    return{
        type: FEE_RECEIPT_REPORT_BY_DATE_ACTION,
        payload:{datefrom, dateto}
    }
}

export const feeReceiptsReportSuccessAction = (feeReceipts: Array<IFeeReceipt>): IFeeReceiptReportSuccessAction =>{
    return {
        type: FEE_RECEIPT_REPORT_SUCCESS_ACTION,
        payload: {feeReceipts }
    }
}

export const feeDueReportAction = (session: string, classId: number, selectLeft: boolean): IFeeDueReportAction => {
    return {
        type: FEE_DUE_REPORT_ACTION,
        payload: {session, classId, selectLeft}
    }
}

export const feeDueReportSuccessAction = (dueFees: Array<IFeeReport>): IFeeDueReportSuccessAction =>{
    return {
        type: FEE_DUE_REPORT_SUCCESS_ACTION,
        payload: {dueFees}
    }
}
import { COMPONENT_NAME } from "../ComponentEnum"
import { IClass } from "../interface/Class"
import { IError } from "../interface/Error"
import { IFeeReceipt, IFeeReceive, IFeeReport } from "../interface/Fee"
import { IRegiStudentDetails, IStudent } from "../interface/Student"
import { IIdentity } from "./IdentityState"

export interface IInitialState{
    isLoading: boolean,
    renderedComponent: string,
    successMessage?: string
    successRecordId?: number,
    identity?: IIdentity,
    classList: Array<IClass>,
    students: Array<IStudent>,
    regiStudents: Array<IRegiStudentDetails>,
    feeReceiveDetail?: IFeeReceive,
    feeReceipts?: Array<IFeeReceipt>,
    dueFees?: Array<IFeeReport>,
    error?: IError,
    ui?: IUIState,
    regiFeeReport: Array<IRegiStudentDetails>
}

export interface IUIState{
    student?: IStudent
    isStudentReadOnly?: boolean 
}


export const initialState: IInitialState = {
    isLoading: false,
    renderedComponent : COMPONENT_NAME.DASHBOARD_COMPONENT,
    regiStudents: [],
    classList: [],
    students: [],
    regiFeeReport: []
}
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { textFieldStyle } from './style';

enum ChangePasswordEnum {
    CurrentPassword,
    NewPassword,
    ConfirmPassword
}
interface IChangePasswordProps {
    onChangePassword: (currentPassword: string, newPassword: string) => void
}
export const ChangePassword = ({ onChangePassword }: IChangePasswordProps): React.ReactElement => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    const [currentPassState, setCurrentPassSate] = useState<string>();
    const [newPassState, setNewPassState] = useState<string>();
    const [confirmPassState, setConfirmPassState] = useState<string>();
    const [isInvalidState, setIsInvalidState] = useState<ChangePasswordEnum>();
    const [validationError, setValidationError] = useState<string>();

    const onInputChange = (val: string, input: ChangePasswordEnum) => {
        switch (input) {
            case ChangePasswordEnum.CurrentPassword:
                setCurrentPassSate(val);
                break;
            case ChangePasswordEnum.NewPassword:
                setNewPassState(val);
                break;
            case ChangePasswordEnum.ConfirmPassword:
                setConfirmPassState(val);
                break;
        }
    }

    const isValid = (): boolean => {
        if (!(currentPassState && currentPassState.length >= 6)) {
            setIsInvalidState(ChangePasswordEnum.CurrentPassword);
            setValidationError(undefined);
            return false;
        }
        const passwordIsValid = passwordRegex.test(newPassState ?? '');
        if (!(newPassState && passwordIsValid)) {
            setIsInvalidState(ChangePasswordEnum.NewPassword);
            if (!passwordIsValid) {
                setValidationError("Password must has at least 6 characters that include at least 1 character, 1 number and 1 special character. Ex. kr@1234");
            }
            return false;
        }
        if (!(newPassState?.length == confirmPassState?.length)) {
            setIsInvalidState(ChangePasswordEnum.ConfirmPassword);
            setValidationError("New password and confirm password should be same.")
            return false;
        }
        setValidationError(undefined);
        return true;
    }

    const onChangePasswordClick = () => {
        if (isValid()) {
            onChangePassword(currentPassState ?? '', newPassState ?? '');
        }
    }

    const onCancel = () => {
        setCurrentPassSate(undefined);
        setNewPassState(undefined);
        setConfirmPassState(undefined);
    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {validationError && <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <span style={{fontSize: '14px', color: 'red'}}>{validationError}</span>
                    </Grid>}
                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState === ChangePasswordEnum.CurrentPassword}
                            id="curr_password" label="Current Password"
                            type="password"
                            value={currentPassState}
                            onChange={(e) => { onInputChange(e.target.value, ChangePasswordEnum.CurrentPassword) }}
                            variant="outlined" style={textFieldStyle} />
                        <br></br>
                        
                    </Grid>

                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="new_password" label="New Password" type="password" value={newPassState}
                            error={isInvalidState === ChangePasswordEnum.NewPassword}
                            onChange={(e) => { onInputChange(e.target.value, ChangePasswordEnum.NewPassword) }}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="confirm_password" label="Confirm Password" type="password" value={confirmPassState}
                            error={isInvalidState === ChangePasswordEnum.ConfirmPassword}
                            onChange={(e) => { onInputChange(e.target.value, ChangePasswordEnum.ConfirmPassword) }}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>

                    <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" color="secondary" onClick={onChangePasswordClick} >Change Password</Button>
                            <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
import { dateToString } from "../Common";
import { IFeeReceipt, IFeeReceive, IFeeReport } from "../interface/Fee";
import { IInitialState, initialState } from "../state/InitialState";

export const feeReceiveDetailSelector = (state: IInitialState): IFeeReceive | undefined => {
    return state.feeReceiveDetail;
}

export const feeReceiptReportSelector = (state: IInitialState): Array<IFeeReceipt> | undefined => {
    const feeReceipts = state.feeReceipts;
    return feeReceipts?.map<IFeeReceipt>((fee: IFeeReceipt) => <IFeeReceipt>{
        id: fee.id, regiNo: fee.regiNo, rollNo: fee.rollNo,
        className: fee.className, studentName: fee.studentName,
        fatherName: fee.fatherName, totalFee: fee.totalFee,
        receivedFee: fee.receivedFee, dueFee: fee.dueFee,
        receivedDate: dateToString(new Date(fee.receivedDate)),
        user: fee.user,
        extraFee: fee.extraFee, isFree : fee.isFree, remark: fee.remark, feeLastChangeBy: fee.feeLastChangeBy
    });
}

export const dueFeeReportSelector = (state: IInitialState): Array<IFeeReport> | undefined => {
    return state.dueFees;
}
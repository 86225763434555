import { IDDL, IStudent } from "../interface/Student";
import { IInitialState } from "../state/InitialState";

export const studentSelectorUI = (state: IInitialState): IStudent|undefined => {
    return state.ui?.student
}

export const studentsSelector = (state: IInitialState): Array<IStudent> => {
    return state.students;
}

export const isReadonlySelector = (state: IInitialState): boolean => {
    return !!state.ui?.isStudentReadOnly;
}

export const studentsDDLSelector = (state: IInitialState): Array<IDDL> => {
    const students = state.students;
    return students.map((st) => <IDDL>{label: `${st.studentName} s/o ${st.fatherName}`, id: st.id});
}

export const regiStudentDDLSelector = (state:IInitialState) : Array<IDDL> =>{
    const regiStudents = state.regiStudents;
    return regiStudents.map((st) => <IDDL>{label: st.studentName, id: st.studentId, className: st.className});
}


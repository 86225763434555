import { IRegistration } from "../interface/Registration";
import { IRegiStudentDetails } from "../interface/Student";
import { IBaseActoin } from "./BaseAction";


export const REGISTER_STUDENT_ACTION = 'REGISTER_STUDENT_ACTION';
export const GET_REGI_STUDENTS_ACTION= 'GET_REGI_STUDENTS_ACTION';
export const GET_REGI_STUDENTS_SUCCESS_ACTION = 'GET_REGI_STUDENTS_SUCCESS_ACTION';
export const PROMOTE_STUDENT_ACTION = 'PROMOTE_STUDENT_ACTION';
export const UPDATE_STUDENT_FEE_ACTION = 'UPDATE_STUDENT_FEE_ACTION';
export const UPDATE_STUDENT_ACTION = 'UPDATE_STUDENT_ACTION';
export const MARK_LEFT_STUDENT_ACTION = 'MARK_LEFT_STUDENT_ACTION';
export const REGI_FEE_REPORT_ACTION = "REGI_FEE_REPORT_ACTION";
export const GET_REGI_FEE_REPORT_SUCCESS_ACTION = "GET_REGI_FEE_REPORT_SUCCESS_ACTION";

export interface IRegisterStudentAction extends IBaseActoin<{regiStudent: IRegistration}>{};
export interface IGetRegiStudentAciton extends IBaseActoin<{session: string, selectLeft: boolean}>{};
export interface IGetRegiStudentsSuccessAction extends IBaseActoin<{students: Array<IRegiStudentDetails>}>{};
export interface IPromoteStudentAction extends IBaseActoin<{regiStudent: IRegistration}>{};
export interface IUpdateStudentAction extends IBaseActoin<{regiStudent: IRegistration}>{};
export interface IMarkLeftStudentAction extends IBaseActoin<{regiId: number, session: string, isLeft: boolean}>{};
export interface IRegiFeeReportAction extends IBaseActoin<{session: string, classId: number}>{};
export interface IRegiFeeReportSuccessAction extends IBaseActoin<{regiFeeReport:Array<IRegiStudentDetails>}>{};

export const registerStudentAction = (regi: IRegistration): IRegisterStudentAction => {
    return {
        type: REGISTER_STUDENT_ACTION,
        payload: {regiStudent: regi}
    }
}

export const getRegiStudentsAction = (session: string, selectLeft: boolean): IGetRegiStudentAciton => {
    return{
        type: GET_REGI_STUDENTS_ACTION,
        payload: {session, selectLeft}
    }
}

export const getRegiStudentSuccessAction = (students: Array<IRegiStudentDetails>): IGetRegiStudentsSuccessAction => {
    return{
        type: GET_REGI_STUDENTS_SUCCESS_ACTION,
        payload: {students}
    }
}

export const promoteStudentAction = (regiStudent: IRegistration): IPromoteStudentAction => {
    return {
        type: PROMOTE_STUDENT_ACTION,
        payload: {regiStudent}
    }
}

export const updateStudentAction = (regiStudent: IRegistration): IUpdateStudentAction => {
    return {
        type: UPDATE_STUDENT_ACTION,
        payload: {regiStudent}
    }
}

export const UpdateStudentFeeAction = (regiStudent: IRegistration): IUpdateStudentAction => {
    return {
        type: UPDATE_STUDENT_FEE_ACTION,
        payload: {regiStudent}
    }
}
export const markLeftStudentAction = (regiId: number, session:string, isLeft: boolean): IMarkLeftStudentAction => {
    return {
        type: MARK_LEFT_STUDENT_ACTION,
        payload: {regiId, session, isLeft}
    }
}

export const regiFeeReportAction = (session: string, classId: number): IRegiFeeReportAction =>{
    return {
        type: REGI_FEE_REPORT_ACTION,
        payload: {session, classId }
    }
}

export const getRegiFeeReportSuccessAction = (regiFeeReport:Array<IRegiStudentDetails>): IRegiFeeReportSuccessAction => {
    return {
        type: GET_REGI_FEE_REPORT_SUCCESS_ACTION,
        payload: {regiFeeReport}
    }
}
import Axios from 'axios';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ERROR_ACTION, IErrorActoin, ISuccessAction, SUCCESS_ACTION } from '../action/BaseAction';
import {
    GET_CLASS_ACTION, GET_CLASS_SUCCESS_ACTION,
    IGetClassSuccessAction, IAddClassAction,
    ADD_CLASS_ACTION,
    UPDATE_CLASS_ACTION,
    IUpdateClassAction
} from "../action/ClassAction";
import { BASE_URL } from "../Env";
import { IClass } from "../interface/Class";
import { createErrorAction, getHeaderWithToken } from './commonSaga';
const classUrl = `${BASE_URL}studentclass/`;
const getClassUrl = `${classUrl}getallclass`;
const updateClassUrl = `${classUrl}updateclass`;
const addClassUrl = `${classUrl}addclass`;
const CLASS_UPDATED = 'Class updated successfully!';
const CLASS_ADDED = 'Class added successfully!';
const formData = new FormData();

const getClassApi = () => {
    return Axios.get<IClass[]>(getClassUrl, {headers: getHeaderWithToken()} ).then(res => res.data);
}
const updateClassApi =(cls: IClass): Promise<any> => {
    return Axios.post<any>(updateClassUrl, cls, {headers: getHeaderWithToken()}).then(res => res.data);
}

const addClassApi = (cls: IClass): Promise<any> => {
    return Axios.post<any>(addClassUrl, cls, {headers: getHeaderWithToken()}).then(res => res.data);
}

function* updateClass(action: IUpdateClassAction) {
    try {
        const response:IClass = yield call(updateClassApi, action.payload.cls);
        yield put({type: GET_CLASS_ACTION, payload: {}});
        const successAction: ISuccessAction = {type: SUCCESS_ACTION, payload: {msg: CLASS_UPDATED}};
        yield put(successAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
    }
}

function* addClass(action: IAddClassAction) {
    try {
        const response:IClass = yield call(addClassApi, action.payload.cls);
        yield put({type: GET_CLASS_ACTION, payload: {}});
        const successAction: ISuccessAction = {type: SUCCESS_ACTION, payload: {msg: CLASS_ADDED}};
        yield put(successAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
    }
}


function* getClass() {
    try {
        const classes: IClass[] = yield call(getClassApi);
        const action: IGetClassSuccessAction = { type: GET_CLASS_SUCCESS_ACTION, payload: { classes: classes } };
        yield put(action);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
    }
}

function* classSaga() {
    yield takeEvery(ADD_CLASS_ACTION, addClass);
    yield takeLatest(GET_CLASS_ACTION, getClass);
    yield takeEvery(UPDATE_CLASS_ACTION, updateClass);
}

export default classSaga;
import { connect } from "react-redux";
import { updateRouteAction } from "../action/UpdateRouteActoin";
import { COMPONENT_NAME } from "../ComponentEnum";
import { IStudent } from "../interface/Student";
import { studentsSelector } from "../selectors/StudentSelector";
import { IInitialState } from "../state/InitialState";
import { Students } from "../Students";
import {editStudentAction, readOnlyStudentAction, resetStudentFlagsAction, studentGetAction} from '../action/StudentAction';
import { IClass } from "../interface/Class";
import { classSelector } from "../selectors/ClassSelector";

interface IStateToProps {
    students: Array<IStudent>,
    classList: Array<IClass>
}

interface IDispatchProps {
    onAddStudent: () => void,
    loadStudent: () => void,
    onEditStudent: (student: IStudent) => void,
    viewStudent: (student: IStudent) => void
}

const mapStateToProps = (state: IInitialState): IStateToProps => {
    return {
        students: studentsSelector(state),
        classList: classSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onAddStudent: () => {
            dispatch(updateRouteAction(COMPONENT_NAME.STUDENT_FORM));
            dispatch(resetStudentFlagsAction());
        },
        loadStudent: () => {
            dispatch(studentGetAction())
        },
        onEditStudent: (student: IStudent) => {
            dispatch(editStudentAction(student));
            dispatch(updateRouteAction(COMPONENT_NAME.STUDENT_FORM));
        },
        viewStudent: (student: IStudent) => {
            dispatch(readOnlyStudentAction(student));
            dispatch(updateRouteAction(COMPONENT_NAME.STUDENT_FORM));
        }
    }
}

export const ConnectedStudents = connect(mapStateToProps, mapDispatchToProps)(Students);
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { getSessions } from "./Common";
import MenuItem from "@mui/material/MenuItem";
import { IClass } from "./interface/Class";
import { IFeeReport } from "./interface/Fee";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

interface IDueFeeReportProps {
    classes: Array<IClass>,
    dueFees: Array<IFeeReport>,
    onSearch: (session: string, classId: number, selectLeft: boolean) => void
}

export const DueFeeReport = ({ classes, dueFees, onSearch }: IDueFeeReportProps): React.ReactElement => {
    const sessions = getSessions();
    const [sessionState, setSessionState] = useState<string>('');
    const [classState, setClassState] = useState<string>('');
    const [statusState, setStatusState] = useState<number>(0);
    const totalFee = dueFees.map(dueFee => dueFee.totalFee).reduce((p, c) => p + c, 0);
    const totalRece = dueFees.map(dueFee => dueFee.feeReceived).reduce((p, c) => p + c, 0);
    
    const columns: GridColDef[] = [
        { field: 'rollNo', headerName: 'Roll No.', width: 70 },
        { field: 'studentName', headerName: 'Student Name', width: 190 },
        { field: 'fatherName', headerName: 'Father Name', width: 190 },
        { field: 'className', headerName: 'Class', width: 120 },
        { field: 'totalFee', headerName: 'Total Fee', width: 80 },
        { field: 'feeReceived', headerName: 'Rece. Fee', width: 80 },
        { field: 'dueFee', headerName: 'Due Fee', width: 80 },
        { field: 'leftDate', headerName: 'Left Date', width: 100 },
    ];
    const onInputChange = (val: string, key: string) => {
        switch (key) {
            case 'SESSION':
                setSessionState(val);
                break;
            case 'CLASS':
                setClassState(val);
                break;
            case 'STATUS':
                setStatusState(parseInt(val));
                break;
        }
    }

    const onSearchClick = () => {
        if (sessionState && classState) {
            onSearch(sessionState, parseInt(classState), statusState !== 0);
        }
    }

    const onPrint = () => {
        const receiptUrl = `https://fee.krinstituteagra.com/fee-report.html?session=${sessionState}&classId=${classState}&selectLeft=${statusState}`;
        window.open(receiptUrl, "Google", "width=800,height=600");
    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="session-label">Session</InputLabel>
                            <Select
                                labelId="session-label"
                                id="session_select"
                                value={sessionState}
                                label="Session"
                                onChange={(e) => { onInputChange(e.target.value, 'SESSION') }}
                            >
                                {sessions.map((session) => (
                                    <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="class-label">Class</InputLabel>
                            <Select
                                labelId="class-label"
                                id="class_select"
                                value={classState}
                                label="Class"
                                error={false}
                                onChange={(e) => { onInputChange(e.target.value, 'CLASS') }}
                            >
                                <MenuItem key={'class_all'} value={'0'}>{'-All-'}</MenuItem>
                                {classes.map(cls => (
                                    <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status_select"
                                value={statusState}
                                label="Status"
                                error={false}
                                onChange={(e) => { onInputChange(e.target.value.toString(), 'STATUS') }}
                            >
                                <MenuItem key={'left'} value={1}>Left</MenuItem>
                                <MenuItem key={'active'} value={0}>Active</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant='contained' color='primary' onClick={onSearchClick}>Search</Button>
                            <Button variant='contained' color='primary' onClick={onPrint}>Print</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <label>Total Fee: {totalFee}</label>
                            <label>Total Rec. Fee: {totalRece}</label>
                            <label>Total Due Fee: {totalFee - totalRece}</label>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={dueFees ?? []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
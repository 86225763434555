import { configureStore } from '@reduxjs/toolkit';
import {applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga/rootSaga';
import rootReducer from './RootReducer';



const sagaMiddleware = createSagaMiddleware();
const store  = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [sagaMiddleware]
});
sagaMiddleware.run(rootSaga);

export default store;
import { connect } from "react-redux";
import { getFeeReceiptsReportByDateAction } from "../action/FeeAction";
import { FeeReceiptReportByDates } from "../FeeReceiptReportByDates";
import { IFeeReceipt } from "../interface/Fee";
import { feeReceiptReportSelector } from "../selectors/FeeSelector";
import { IInitialState } from "../state/InitialState";

interface IStateProps {
    feeReceipts: Array<IFeeReceipt>
}

interface IDispatchProps {
    getFeeReceipt: (datefrom: string, dateto: string) => void
}

const mapStateToProps = (state: IInitialState): IStateProps => {
    return {
        feeReceipts: feeReceiptReportSelector(state) ?? []
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        getFeeReceipt: (datefrom: string, dateto: string) => {
            dispatch(getFeeReceiptsReportByDateAction(datefrom, dateto));
        }
    }
}

export const ConnectedFeeReceiptReportBydate = connect(mapStateToProps, mapDispatchToProps)(FeeReceiptReportByDates);
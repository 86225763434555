import Axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { ISuccessAction, SUCCESS_ACTION } from "../action/BaseAction";
import { FEE_DUE_REPORT_ACTION, FEE_DUE_REPORT_SUCCESS_ACTION, FEE_RECEIPT_REPORT_BY_DATE_ACTION, FEE_RECEIPT_REPORT_SUCCESS_ACTION, FEE_RECEIVE_ACTION, GET_FEE_RECEIVE_DETAIL_ACTION, GET_FEE_RECEIVE_DETAIL_BY_STUDENT_ACTION, GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION, IFeeDueReportAction, IFeeDueReportSuccessAction, IFeeReceiptReportByDateAction, IFeeReceiptReportSuccessAction, IFeeReceiveAction, IGetFeeReceiveDetailAction, IGetFeeReceiveDetailByStudentAction, IGetFeeReceiveDetailSuccessAction } from "../action/FeeAction";
import { BASE_URL } from "../Env";
import { IFee, IFeeReceipt, IFeeReceive, IFeeReport } from "../interface/Fee";
import { createErrorAction, createLoadingAction, getHeaderWithToken } from "./commonSaga";

const feeUrl = `${BASE_URL}fee/`;
const getFeeReceiveDetailUrl = `${feeUrl}getFeeReceiveDetails`;
const getFeeReceiveDetailByStudentUrl = `${feeUrl}getFeeReceiveDetailsByStudent`;
const feeReceiveUrl = `${feeUrl}feeReceive`;
const feeReceiptReportByDateUrl = `${feeUrl}getReceivedFeesByDates`;
const FEE_RECEIVE_SUCCESS = "Fee received successfully!";


const getDueFeeReportApi= (session: string, classId: number, selectLeft: boolean): Promise<IFeeReport> => {
    return Axios.get<IFeeReport>(`${feeUrl}getFeeDueReport/${session}/${classId}?selectLeft=${selectLeft}`).then(res => res.data);
}
const getFeeReceiveDetailsApi = (classId: number, rollNo: number, session: string): Promise<IFeeReceive> => {
    return Axios.get<IFeeReceive>(`${getFeeReceiveDetailUrl}/${classId}/${rollNo}/${session}`, { headers: getHeaderWithToken() }).then(res => res.data);
}

const getFeeReceiveDetailsByStudentApi = (classId: number, studentId: number, session: string): Promise<IFeeReceive> => {
    return Axios.get<IFeeReceive>(`${getFeeReceiveDetailByStudentUrl}/${classId}/${studentId}/${session}`, { headers: getHeaderWithToken() }).then(res => res.data);
}

const feeReceiveApi = (fee: IFee): Promise<IFee> => {
    return Axios.post<IFee>(feeReceiveUrl, fee, { headers: getHeaderWithToken() }).then(res => res.data);
}

const getFeeReceiptReportByDateApi = (datefrom: string, dateto: string): Promise<Array<IFeeReceipt>> => {
    return Axios.get<Array<IFeeReceipt>>(`${feeReceiptReportByDateUrl}/${datefrom}/${dateto}`, { headers: getHeaderWithToken() }).then(res => res.data);
}

const instanceOfFeeReceiveDetails = (obj: any): obj is IGetFeeReceiveDetailAction => {
    return 'rollNo' in obj.payload;
}
const instanceOfFeeReceiveDetailsByStudent = (obj: any): obj is IGetFeeReceiveDetailByStudentAction => {
    return 'studentId' in obj.payload;
}
function* getFeeReceiveDetails(action: IGetFeeReceiveDetailAction | IGetFeeReceiveDetailByStudentAction) {
    debugger
    try {
        yield put(createLoadingAction(true));

        if (instanceOfFeeReceiveDetails(action) && action.type == GET_FEE_RECEIVE_DETAIL_ACTION) {
            const { classId, rollNo, session } = action.payload;
            const feeReceive: IFeeReceive = yield call(getFeeReceiveDetailsApi, classId, rollNo, session);
            const successAction: IGetFeeReceiveDetailSuccessAction = {
                type: GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION,
                payload: { feeReceiveDetail: feeReceive }
            }
            yield put(successAction);
        } else if (instanceOfFeeReceiveDetailsByStudent(action)) {
            const { classId, studentId, session } = action.payload;
            const feeReceive: IFeeReceive = yield call(getFeeReceiveDetailsByStudentApi, classId, studentId, session);
            const successAction: IGetFeeReceiveDetailSuccessAction = {
                type: GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION,
                payload: { feeReceiveDetail: feeReceive }
            }
            yield put(successAction);
        }

        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* feeReceive(action: IFeeReceiveAction) {
    try {
        yield put(createLoadingAction(true));
        const feeReceive: IFeeReceive = yield call(feeReceiveApi, action.payload.feeReceive);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: FEE_RECEIVE_SUCCESS, successRecordId: parseInt(feeReceive.toString()) } };
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* getFeeReceiptReportByDate(action: IFeeReceiptReportByDateAction) {
    try {
        yield put(createLoadingAction(true));
        const feeReceipts: Array<IFeeReceipt> = yield call(getFeeReceiptReportByDateApi, action.payload.datefrom, action.payload.dateto);
        const successAction: IFeeReceiptReportSuccessAction = { type: FEE_RECEIPT_REPORT_SUCCESS_ACTION, payload: { feeReceipts } };
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* getDueFeeReport(action: IFeeDueReportAction) {
    try{
        yield put(createLoadingAction(true));
        const dueFees: Array<IFeeReport> = yield call(getDueFeeReportApi, action.payload.session, action.payload.classId, action.payload.selectLeft);
        const successAction:IFeeDueReportSuccessAction = {type: FEE_DUE_REPORT_SUCCESS_ACTION, payload: {dueFees}};
        yield put(successAction);
        yield put(createLoadingAction(false));
    }catch(ex: any){
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* FeeSaga() {
    yield takeEvery(GET_FEE_RECEIVE_DETAIL_ACTION, getFeeReceiveDetails);
    yield takeEvery(GET_FEE_RECEIVE_DETAIL_BY_STUDENT_ACTION, getFeeReceiveDetails);
    yield takeEvery(FEE_RECEIVE_ACTION, feeReceive);
    yield takeEvery(FEE_RECEIPT_REPORT_BY_DATE_ACTION, getFeeReceiptReportByDate);
    yield takeEvery(FEE_DUE_REPORT_ACTION, getDueFeeReport)
}

export default FeeSaga;
import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import store from './reducer/Store';
import { ConnectedLoading } from './connected/ConnectedLoading';
import { ConnectedAuthContainer } from './connected/ConnectedAuthContainer';

function App() {
  return (
    <Provider store={store}>
    <ConnectedAuthContainer/>
    <ConnectedLoading/>
    </Provider>
  );
}

export default App;

import { IClearFeeStateAction, IFeeDueReportSuccessAction, IFeeReceiptReportSuccessAction, IGetFeeReceiveDetailSuccessAction } from "../action/FeeAction";
import { IInitialState } from "../state/InitialState";

export const feeReceiveDetailReducer = (state: IInitialState, action: IGetFeeReceiveDetailSuccessAction): IInitialState => {
    return {...state, feeReceiveDetail: action.payload.feeReceiveDetail}
}

export const clearFeeStateReducer = (state: IInitialState, action: IClearFeeStateAction): IInitialState => {
    return {...state, feeReceiveDetail: undefined};
} 

export const feeReceiptReportReducer = (state: IInitialState, action: IFeeReceiptReportSuccessAction): IInitialState =>{
    return {...state, feeReceipts: action.payload.feeReceipts};
}

export const dueFeeReportReducer = (state: IInitialState, action: IFeeDueReportSuccessAction): IInitialState => {
    return {...state, dueFees: action.payload.dueFees};
}
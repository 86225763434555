export const textFieldStyle = {
    width: '100%'
}
export const borderStyle = {
    borderBottom: '1px solid rgb(0 0 0 / 18%)',
    borderBottomWidth: 'thin',
    borderWidth: '',
    borderTop: '1px solid rgb(0 0 0 / 18%)',
    padding: '5px',
    fontSize: '14px',
    fontWeight: '600'
}
export const borderBottomStyle = {
    borderBottom: '1px solid rgb(0 0 0 / 10%)',
    padding: '5px',
    fontSize: '14px'
}

export const simpleInput = {
    padding: '5px 5px',
    width: '70px'
}
import { IClass } from "../interface/Class";
import { IBaseActoin } from "./BaseAction";

export const GET_CLASS_ACTION = 'GET_CLASS_ACTION';
export const GET_CLASS_SUCCESS_ACTION = 'GET_CLASS_SUCCESS_ACTION';
export const ADD_CLASS_ACTION = 'ADD_CLASS_ACTION';
export const UPDATE_CLASS_ACTION = 'UPDATE_CLASS_ACTION';

export interface IGetClassSuccessAction extends IBaseActoin<{classes: Array<IClass>}>{};
export interface IGetClassAction extends IBaseActoin{};
export interface IAddClassAction extends IBaseActoin<{cls: IClass}>{}
export interface IUpdateClassAction extends IBaseActoin<{cls: IClass}>{}

export const getClassActoin = (): IGetClassAction =>{ 
    return {
        type : GET_CLASS_ACTION,
        payload: {}
    }
}

export const getClassSuccess = (classes: Array<IClass>): IGetClassSuccessAction =>{
    return {
        type: GET_CLASS_SUCCESS_ACTION,
        payload: {classes}
    }
}

export const addClassAction = (cls: IClass): IAddClassAction => {
    return {
        type: ADD_CLASS_ACTION,
        payload: {cls}
    }
}

export const updateClassAction = (cls: IClass): IUpdateClassAction => {
    return {
        type: UPDATE_CLASS_ACTION,
        payload: {cls}
    }
}



import { AlertColor } from "@mui/material";
import { connect } from "react-redux";
import { closeAlertAction } from "../action/BaseAction";
import { AlertComponent } from "../Alert";
import { errorSelector } from "../selectors/ErrorSelector";
import { successMessageSelector } from "../selectors/SuccessMsgSelector";
import { IInitialState } from "../state/InitialState";


interface IStateToProps {
    alertType: AlertColor,
    msg?: string
}

interface IDispatchProps {
    onCloseAlert: () => void;
}

const mapStateToProps = (state: IInitialState): IStateToProps =>{
    const error = errorSelector(state);
    return {
        alertType: error ? 'error' : 'success',
        msg: error ? error.message : successMessageSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onCloseAlert: () => { dispatch(closeAlertAction())}
    }
}

export const ConnectedAlertComponent = connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
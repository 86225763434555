import { AxiosRequestHeaders } from "axios";
import { ERROR_ACTION, IErrorActoin, ILoadingAction, LOADING_ACTION } from "../action/BaseAction";
import { IIdentityAction, UPDATE_IDENTITY_ACTION } from "../action/UpdateIdentityAction";
import { IError } from "../interface/Error";
import { IIdentity } from "../state/IdentityState";

export const NOT_FOUND = 'API Not Found!';
export const createErrorAction = (ex: any): IErrorActoin | IIdentityAction => {
    const errorCode = ex?.code == "ERR_NETWORK" ? ex.code : ex.response.status;
    const msg = errorCode == "ERR_NETWORK"? ex.message : errorCode == 404 ? NOT_FOUND : ex.response.data.message;
    const error: IError = { code: errorCode, message: msg };
    if(errorCode == 401){
       return {type: UPDATE_IDENTITY_ACTION, payload: {}} as IIdentityAction;
    }
    return { type: ERROR_ACTION, payload: { error } } as IErrorActoin;
}

export const createLoadingAction = (isLoading: boolean): ILoadingAction => {
    return {type: LOADING_ACTION, payload: {isLoading}} as ILoadingAction;
}

export const getHeaderWithToken = (): AxiosRequestHeaders => {
    const identity:IIdentity = JSON.parse(localStorage.getItem('token') ?? ""); 
    const headers: AxiosRequestHeaders = {'content-type': 'application/json; charset=utf-8', 'Authorization': `bearer ${identity?.access_token}`};
    return headers;
}
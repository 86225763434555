import { connect } from "react-redux";
import { signInAction } from "../action/UpdateIdentityAction";
import { SignIn } from "../Singin";

interface IDispatchProps {
    onSignIn: (userName: string, passwore: string) => void
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onSignIn: (userName: string, password: string) => {
            dispatch(signInAction(userName, password))
        }
    }
}

export const ConnectedSignIn = connect(null, mapDispatchToProps)(SignIn);
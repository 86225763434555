import { CLOSE_ALERT, ERROR_ACTION, LOADING_ACTION, SUCCESS_ACTION } from "../action/BaseAction";
import { GET_CLASS_SUCCESS_ACTION } from "../action/ClassAction";
import { CLEAR_FEE_STATE_ACTION, FEE_DUE_REPORT_SUCCESS_ACTION, FEE_RECEIPT_REPORT_SUCCESS_ACTION, GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION } from "../action/FeeAction";
import { GET_REGI_FEE_REPORT_SUCCESS_ACTION, GET_REGI_STUDENTS_SUCCESS_ACTION } from "../action/RegistrationAction";
import { RESET_STUDENT_FLAG_ACTION, STUDENT_EDIT_ACTION, STUDENT_GET_SUCCESS, STUDENT_READONLY_ACTION, STUDENT_STATE_UPDATE_ACTION } from "../action/StudentAction";
import { UPDATE_IDENTITY_ACTION } from "../action/UpdateIdentityAction";
import { UPDATE_ROUTE_ACTION } from "../action/UpdateRouteActoin";
import { IInitialState, initialState } from "../state/InitialState";
import { updateClassReducer } from "./ClassReducer";
import { clearFeeStateReducer, dueFeeReportReducer, feeReceiptReportReducer, feeReceiveDetailReducer } from "./FeeReducer";
import { identityReducer } from "./IdentityReducer";
import { getRegiStudentReducer, regiFeesReducer } from "./RegistrationReducer";
import {
    editStudentReducer, getStudentSuccessReducer,
    readOnlyStudentReducer, resetStudentFlagReducer, updateStudentStateReducer
} from "./StudentReducer";

const errorReducer = (state: IInitialState, action: any): IInitialState => {
    return { ...state, error: action.payload.error, successMessage: undefined }
}
const resetErrorAndMsg = (state: IInitialState, action: any): IInitialState => {
    return { ...state, error: undefined, successMessage: undefined, successRecordId: undefined };
}

const successReducer = (state: IInitialState, action: any): IInitialState => {
    return { ...state, successMessage: action.payload.msg, successRecordId: action.payload.successRecordId }
}

const loadingReducer = (state: IInitialState, action: any): IInitialState => {
    return {...state, isLoading: action.payload.isLoading};
}
const rootReducer = (state: IInitialState = initialState, action: any): IInitialState => {
    switch (action.type) {
        case UPDATE_ROUTE_ACTION:
            return { ...state, renderedComponent: action.payload.componentName }
        case UPDATE_IDENTITY_ACTION:
            return identityReducer(state, action);
        case GET_CLASS_SUCCESS_ACTION:
            return updateClassReducer(state, action);
        case SUCCESS_ACTION:
            return successReducer(state, action);
        case STUDENT_GET_SUCCESS:
            return getStudentSuccessReducer(state, action);
        case STUDENT_EDIT_ACTION:
            return editStudentReducer(state, action);
        case STUDENT_READONLY_ACTION:
            return readOnlyStudentReducer(state, action);
        case STUDENT_STATE_UPDATE_ACTION:
            return updateStudentStateReducer(state, action);
        case RESET_STUDENT_FLAG_ACTION:
            return resetStudentFlagReducer(state, action);
        case GET_REGI_STUDENTS_SUCCESS_ACTION:
            return getRegiStudentReducer(state, action);
        case GET_FEE_RECEIVE_DETAIL_SUCCESS_ACTION:
            return feeReceiveDetailReducer(state, action);
        case CLEAR_FEE_STATE_ACTION:
            return clearFeeStateReducer(state, action);
        case FEE_RECEIPT_REPORT_SUCCESS_ACTION:
            return feeReceiptReportReducer(state, action);
        case FEE_DUE_REPORT_SUCCESS_ACTION:
            return dueFeeReportReducer(state, action);
        case GET_REGI_FEE_REPORT_SUCCESS_ACTION:
            return regiFeesReducer(state, action);
        case ERROR_ACTION:
            return errorReducer(state, action);
        case CLOSE_ALERT:
            return resetErrorAndMsg(state, action);
        case LOADING_ACTION:
            return loadingReducer(state, action);
        default:
            return { ...state }
    }
}

export default rootReducer;
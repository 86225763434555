import Axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { ISuccessAction, SUCCESS_ACTION } from "../action/BaseAction";
import { IStudentAddAction, IStudentGetSuccessAction, STUDENT_ADD_ACTION, STUDENT_GET_ACTION, STUDENT_GET_SUCCESS, STUDENT_STATE_UPDATE_ACTION, STUDENT_UPDATE_ACTION } from "../action/StudentAction";
import { BASE_URL } from "../Env";
import { IStudent } from "../interface/Student";
import { createErrorAction, createLoadingAction, getHeaderWithToken } from "./commonSaga";

const url = `${BASE_URL}student/`;
const addStudentUrl = `${url}addStudent`;
const updateStudentUrl = `${url}updateStudent`;
const getStudentUrl = `${url}getStudents`;

const ADD_STUDENT_SUCCESS = 'Student added successfully!';
const UPDATE_STUDENT_SUCCESS = "Student updated successfully!";
const addStudentApi = (student: IStudent): Promise<any> => {
    return Axios.post<any>(addStudentUrl, student, {headers: getHeaderWithToken()}).then(res => res.data);
}

const updateStudentApi = (student: IStudent): Promise<any> => {
    return Axios.post<any>(updateStudentUrl, student, {headers: getHeaderWithToken()}).then(res => res.data);
}

const getStudentApi = (): Promise<Array<IStudent>> => {
    return Axios.get<Array<IStudent>>(getStudentUrl, {headers: getHeaderWithToken()}).then(res => res.data);
}

function* getStudent() {
    try {
        const response: Array<IStudent> = yield call(getStudentApi);
        const successAction: IStudentGetSuccessAction = { type: STUDENT_GET_SUCCESS, payload: { students: response } };
        yield put(successAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
    }
}
function* updateStudent(action: IStudentAddAction) {
    try {
        yield put(createLoadingAction(true));
        const response: IStudent = yield call(updateStudentApi, action.payload.student);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: UPDATE_STUDENT_SUCCESS } };
        const studentStateUpdateAction: IStudentAddAction = { type: STUDENT_STATE_UPDATE_ACTION, payload: { student: response } }
        yield put(createLoadingAction(false));
        yield put(successAction);
        yield put(studentStateUpdateAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* addStudent(action: IStudentAddAction) {
    try {
        yield put(createLoadingAction(true));
        const response: IStudent = yield call(addStudentApi, action.payload.student);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: ADD_STUDENT_SUCCESS } };
        const studentStateUpdateAction: IStudentAddAction = { type: STUDENT_STATE_UPDATE_ACTION, payload: { student: response } }
        yield put(createLoadingAction(false));
        yield put(successAction);
        yield put(studentStateUpdateAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* studentSaga() {
    yield takeEvery(STUDENT_ADD_ACTION, addStudent);
    yield takeEvery(STUDENT_UPDATE_ACTION, updateStudent);
    yield takeEvery(STUDENT_GET_ACTION, getStudent);
}

export default studentSaga;
export enum COMPONENT_NAME {
    DASHBOARD_COMPONENT = 'DashBoard',
    CLASS_COMPONENT = 'Class',
    STUDENT_FORM = 'StudentForm',
    STUDENTS = 'Students',
    REGISTRATION='Registration',
    REGI_STUDENTS='Regi_Students',
    RECEIVE_FEE = 'Receive_Fee',
    FEE_BY_DATE= 'FEE_BY_DATE',
    DUE_FEE_REPORT='DUE_FEE_REPORT',
    CHANGE_PASSWORD='CHANGE_PASSWORD',
    REGI_FEE_REPORT = "REGI_FEE_REPORT"
}
import { connect } from "react-redux";
import { getRegiStudentsAction, markLeftStudentAction, promoteStudentAction, updateStudentAction, UpdateStudentFeeAction } from "../action/RegistrationAction";
import { IClass } from "../interface/Class";
import { IRegistration } from "../interface/Registration";
import { IRegiStudentDetails } from "../interface/Student";
import { RegiStudents } from "../RegiStudents";
import { classSelector } from "../selectors/ClassSelector";
import { loadingSelector } from "../selectors/LoadingSelector";
import { getRegiStudentsSelector } from "../selectors/RegistrationSelector";
import { IInitialState } from "../state/InitialState";

interface IStateToProps{
    regiStudents: Array<IRegiStudentDetails>,
    isLoading: boolean,
    classes: Array<IClass>
}

interface IDispatchToProps {
    onGetRegiStudents: (session: string, selectLeft: boolean) => void,
    onPromoteStudent: (regi: IRegistration) => void,
    onUpdateStudent: (regi: IRegistration) => void,
    onUpdateStudentFee: (regi: IRegistration) => void,
    onMarkLeft: (regiId: number, session:string, isLeft: boolean) => void
}

const mapStateToProps = (state: IInitialState): IStateToProps => {
    return{
        regiStudents: getRegiStudentsSelector(state),
        isLoading: loadingSelector(state),
        classes: classSelector(state),
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchToProps => {
    return {
        onGetRegiStudents: (session: string, selectLeft: boolean)=>{
            dispatch(getRegiStudentsAction(session, selectLeft));
        },
        onPromoteStudent: (regi: IRegistration) => {
            dispatch(promoteStudentAction(regi));
        },
        onUpdateStudentFee: (regi: IRegistration) => {
            dispatch(UpdateStudentFeeAction(regi));
        },
        onUpdateStudent: (regi: IRegistration) =>{
            dispatch(updateStudentAction(regi));
        },
        onMarkLeft: (regiId: number, session:string, isLeft: boolean) => {
            dispatch(markLeftStudentAction(regiId,session, isLeft));
        }
    }
}

export const ConnectedRegiStudents = connect(mapStateToProps, mapDispatchToProps)(RegiStudents);
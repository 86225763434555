import * as React from 'react';
import {connect} from 'react-redux';
import { updateRouteAction } from '../action/UpdateRouteActoin';
import { DashboardLayout } from '../DashboardLayout';
import {getClassActoin} from '../action/ClassAction';
import { signoutAction } from '../action/UpdateIdentityAction';

interface IDispatchProps {
    updateRoute: (componentName: string) => void,
    loadClass: () => void,
    onLogout: () => void
}

const mapDispatchToProps = (dispatch:any): IDispatchProps =>{
    return {
        updateRoute : (componentName: string) =>{
            dispatch(updateRouteAction(componentName));
        },
        loadClass: () => {
            dispatch(getClassActoin());
        },
        onLogout: () => {
            dispatch(signoutAction())
        }
    }
}

export const ConnectedDashboardLayout = connect(null, mapDispatchToProps)(DashboardLayout);
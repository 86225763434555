import React, { useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AddIcon from '@mui/icons-material/Add'; 
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import {borderStyle, borderBottomStyle} from './style';
import { IStudent } from './interface/Student';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { dateToString } from './Common';
import { IClass } from './interface/Class';


interface IStudentsProps {
    classList: Array<IClass>,
    students: Array<IStudent>,
    onAddStudent: () => void,
    loadStudent: () => void,
    onEditStudent: (student: IStudent) => void,
    viewStudent: (student: IStudent) => void
}
export const Students = ({classList, students, onAddStudent, loadStudent, onEditStudent, viewStudent}: IStudentsProps): React.ReactElement => {
    useEffect(()=>{
       students.length <= 0 && loadStudent();
    }, [])
    const getClassName = (id: number): string => {
        if (id > 0) {
            return classList.filter(cls => cls.id == id)?.[0].name;
        } else {
            return '';
        }
    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}><h3> Students</h3></Grid>
                    <Grid item xs={12} md={6}  style={{ display: "flex", justifyContent: "flex-end", padding: "12px 0px" }}>
                     <Button variant="contained" startIcon={<AddIcon />} onClick={() => onAddStudent()}>
                        Add Student
                    </Button>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item xs={12} >
                        <Grid container style={borderStyle}>
                            <Grid item xs={6} md={3}>Student Name</Grid>
                            <Grid item xs={6} md={3}>Father's Name</Grid>
                            <Grid item xs={3} md={2}>Gender</Grid>
                            <Grid item xs={3} md={2} >DOB</Grid>
                            <Grid item xs={3} md={1}>Class</Grid>
                            <Grid item xs={1} md={1}>#</Grid>
                        </Grid>
                    </Grid>
                    {students.map(st => (
                        <Grid item xs={12} key={'student_'+st.id} >
                        <Grid container style={borderBottomStyle}>
                            <Grid item xs={6} md={3}>{st.studentName}</Grid>
                            <Grid item xs={6} md={3}>{st.fatherName}</Grid>
                            <Grid item xs={3} md={2}>{st.gender}</Grid>
                            <Grid item xs={3} md={2} >{dateToString(new Date(st.dob))}</Grid>
                            <Grid item xs={3} md={1}>{getClassName(st.classId)}</Grid>
                            <Grid item xs={1} md={1}>
                            <EditIcon onClick={()=>{onEditStudent(st)}}/>
                            <VisibilityOutlinedIcon onClick={()=> {viewStudent(st)}}></VisibilityOutlinedIcon>
                            </Grid>
                        </Grid>
                    </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    )
}
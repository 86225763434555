import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export const Dashboard = (): React.ReactElement => {
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
            </Paper>
        </Grid>
    )
}
import { connect } from "react-redux";
import { feeDueReportAction } from "../action/FeeAction";
import { DueFeeReport } from "../DueFeeReport";
import { IClass } from "../interface/Class";
import { IFeeReport } from "../interface/Fee";
import { classSelector } from "../selectors/ClassSelector";
import { dueFeeReportSelector } from "../selectors/FeeSelector";
import { IInitialState } from "../state/InitialState";

interface IStateProps {
    classes: Array<IClass>,   
    dueFees: Array<IFeeReport>
}

interface IDispatchProps {
    onSearch: (session: string, classId: number, selectLeft: boolean) => void;
}

const mapStateToProps = (state: IInitialState): IStateProps =>{
    return {
        classes: classSelector(state),
        dueFees: dueFeeReportSelector(state) ?? []
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onSearch: (session: string, classId: number, selectLeft: boolean) => {
            dispatch(feeDueReportAction(session, classId, selectLeft));
        }
    }
}
export const ConnectedDueFeeReport = connect(mapStateToProps, mapDispatchToProps)(DueFeeReport)
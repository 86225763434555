import { connect } from "react-redux";
import { registerStudentAction } from "../action/RegistrationAction";
import { studentGetAction } from "../action/StudentAction";
import { IClass } from "../interface/Class";
import { IRegistration } from "../interface/Registration";
import { IDDL } from "../interface/Student";
import { RegistrationForm } from "../Registrations";
import { classSelector } from "../selectors/ClassSelector";
import { successMessageSelector, successRecordIdSelector } from "../selectors/SuccessMsgSelector";
import { studentsDDLSelector } from "../selectors/StudentSelector";
import { IInitialState } from "../state/InitialState";

interface IStateToProps {
    students: Array<IDDL>,
    classes: Array<IClass>,
    isSuccess: boolean,
    regiId?: number
}

interface IDispatchToProps{
    loadStudent: () => void,
    onRegiStudent: (regiStudent: IRegistration) => void
}


const mapStateToProps = (state: IInitialState): IStateToProps => {
    return {
        students: studentsDDLSelector(state),
        classes: classSelector(state),
        isSuccess: !!successMessageSelector(state),
        regiId: successRecordIdSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchToProps => {
    return {
        loadStudent: () => {
            dispatch(studentGetAction());
        },
        onRegiStudent: (regiStudent: IRegistration) => {
            dispatch(registerStudentAction(regiStudent));
        }
    }
}

export const ConnectedRegistration = connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
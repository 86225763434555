import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { IClass } from './interface/Class';
import { textFieldStyle } from './style';
import { getCurrentSession, getSessions } from './Common';
import { IFee, IFeeReceive } from './interface/Fee';
import { IDDL } from './interface/Student';
import Autocomplete from '@mui/material/Autocomplete';



enum FeeReceiveControl {
    SESSION,
    CLASS,
    ROLL_NO,
    RECEIVING_FEE,
    STUDENT_NAME
}
interface ReceiveFeeProps {
    classes: Array<IClass>,
    feeDetail?: IFeeReceive,
    succeededFeeId?: number,
    isError: boolean,
    students: Array<IDDL>,
    getFeeDetail: (classId: number, rollNo: number, session: string) => void,
    getFeeDetailByStudent: (classId: number, studentId: number, session: string) => void,
    onFeeReceive: (fee: IFee) => void,
    clearFeeState: () => void,
    loadStudent: (session: string) => void
}

export const ReceiveFee = ({ classes, feeDetail, succeededFeeId, isError, students, getFeeDetail, onFeeReceive, clearFeeState, loadStudent, getFeeDetailByStudent }: ReceiveFeeProps): React.ReactElement => {
    
    const sessions = getSessions();
    const getStudent = () => students?.find(x=>x.id == feeDetail?.studentId);
    const [classState, setClassState] = useState<string>('');
    const [rollNoState, setRollNoState] = useState<string>(feeDetail?.rollNo.toString() ?? '');
    const [sessionState, setSessionState] = useState<string>('');
    const [receivingFeeState, setReceivingFeeState] = useState<string>('');
    const [dueFeeState, setDueFeeState] = useState<string>('');
    const [studentState, setStudentState] = useState<IDDL>();
    //filter students based on class
    const className = classState ? classes.find(x => x.id == parseInt(classState))?.name : '';
    const filtered_students = className ? students.filter(x=>x.className == className): students;
    
    
    const getFeeDetailByRollNo = () => {
        (sessionState && parseInt(classState))
            &&  (parseInt(rollNoState))
            && getFeeDetail(parseInt(classState), parseInt(rollNoState), sessionState);
    }
    const getFeeDetailByStudentId = () => {
        (sessionState && parseInt(classState))
            &&  (studentState)
            && getFeeDetailByStudent(parseInt(classState), parseInt(studentState?.id.toString()), sessionState);
    }
    const onCalculateDueFee = () => {
        if (feeDetail) {
            const receivingFee = receivingFeeState ? parseInt(receivingFeeState) : 0;
            const dueFee = (feeDetail?.totalFee ?? 0) - ((feeDetail?.totalReceivedFee ?? 0) + receivingFee);
            setDueFeeState(dueFee.toString());
        }
    }

    const onInputChange = (val: any, inputType: FeeReceiveControl) => {
        switch (inputType) {
            case FeeReceiveControl.SESSION:
                setSessionState(val);
                loadStudent(val);
                break;
            case FeeReceiveControl.CLASS:
                setClassState(val);
                break;
            case FeeReceiveControl.ROLL_NO:
                setRollNoState(val);
                break;
            case FeeReceiveControl.RECEIVING_FEE:
                setReceivingFeeState(val);
                break;
            case FeeReceiveControl.STUDENT_NAME:
                setStudentState(val);

                console.log(val);
                break
        }
    }
    const clear = ()=>{
        setSessionState('');
        setClassState('');
        setRollNoState('');
        setReceivingFeeState('');
        setDueFeeState('');
        clearFeeState();
    }
    const onSubmit = () => {
        
        if (feeDetail && receivingFeeState && parseInt(receivingFeeState) > 0) {
            const fee: IFee = {
                id: 0,
                regiId: feeDetail.regiId,
                session: sessionState,
                totalFee: feeDetail.totalFee,
                feeReceived: parseInt(receivingFeeState),
                dueFee: parseInt(dueFeeState)
            };
            onFeeReceive(fee);
        }
    }
    
    useEffect(() => {
        if (rollNoState){
            getFeeDetailByRollNo();
        } else {
            getFeeDetailByStudentId();
        }
    }, [classState, sessionState, studentState]);

    useEffect(() => {
        onCalculateDueFee();
        if(feeDetail){
            setRollNoState(feeDetail?.rollNo.toString());
        }
    }, [feeDetail, receivingFeeState]);

    useEffect(() => {
        debugger
        if (succeededFeeId && succeededFeeId > 0) {
            const receiptUrl = `https://fee.krinstituteagra.com/receipt.html?id=${succeededFeeId}`;
            window.open(receiptUrl, "Google", "width=720,height=500");
        }
        clear();
    }, [succeededFeeId, isError]);

    useEffect(() => {
        const currentSession = getCurrentSession();
        setSessionState(currentSession);
        students.length <= 0 && loadStudent(currentSession);
    }, []);

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="session-label">Session</InputLabel>
                            <Select
                                labelId="session-label"
                                id="session_select"
                                value={sessionState}
                                label="Session"
                                onChange={(e) => { onInputChange(e.target.value, FeeReceiveControl.SESSION) }}
                            >
                                {sessions.map((session) => (
                                    <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="class-label">Class</InputLabel>
                            <Select
                                labelId="class-label"
                                id="class_select"
                                value={classState}
                                label="Class"
                                error={false}
                                onChange={(e) => { onInputChange(e.target.value, FeeReceiveControl.CLASS) }}
                            >
                                {classes.map(cls => (
                                    <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_rollno" label="Roll No." value={rollNoState}
                            onChange={(e) => { onInputChange(e.target.value, FeeReceiveControl.ROLL_NO) }}
                            onBlur={() => getFeeDetailByRollNo()}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                    {rollNoState && (<TextField required={true}
                            id="student_name" label="Student Name" value={feeDetail?.studentName ?? ''}
                            disabled
                            variant="outlined" style={textFieldStyle} />)} 
                    {!rollNoState && (<Autocomplete
                            disablePortal
                            id="select_student"
                            options={filtered_students}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                            getOptionLabel={(option) => option.label}
                            fullWidth
                            value={studentState}
                            renderInput={(params) => <TextField {...params} label="Student Name" />}
                            onChange = {(e, val)=> {onInputChange(val, FeeReceiveControl.STUDENT_NAME)}}
                        />)}
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_father_name" label="Father's Name" value={feeDetail?.fatherName ?? ''}
                            disabled
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_total_fee" label="Total Fee" value={feeDetail?.totalFee ?? 0}
                            disabled
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_received_fee" label="Received Fee" value={feeDetail?.totalReceivedFee ?? 0}
                            disabled
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_receive_fee" label="Receiving Fee" value={receivingFeeState}
                            error={false}
                            onChange={(e) => { onInputChange(e.target.value, FeeReceiveControl.RECEIVING_FEE) }}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="student_due_fee" label="Due Fee" value={dueFeeState}
                            disabled
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant='contained' color='primary' onClick={onSubmit}>Save</Button>
                            <Button variant="outlined" color="secondary" onClick={clear}>Cancel</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
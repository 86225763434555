import Axios, { AxiosRequestConfig } from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { json } from "stream/consumers";
import { ERROR_ACTION, IErrorActoin, ISuccessAction, SUCCESS_ACTION } from "../action/BaseAction";
import { CHANGE_PASSWORD_ACTION, IChangePasswordAction, IIdentityAction, ISigninAction, SIGNIN_ACTION, SIGN_OUT_ACTION, UPDATE_IDENTITY_ACTION } from "../action/UpdateIdentityAction";
import { BASE_URL } from "../Env";
import { IError } from "../interface/Error";
import { IIdentity } from "../state/IdentityState";
import { createErrorAction, createLoadingAction, getHeaderWithToken } from "./commonSaga";

const tokenUrl = `${BASE_URL.replace('api/', 'Token')}`;
const logoutUrl = `${BASE_URL}Account/Logout`;
const changePasswordUrl = `${BASE_URL}Account/ChangePassword`

const PASSWORD_CHANGE_SUCCESS = "Password has been changed successfully.";

const changePasswordApi = (currentPassword: string, newPassword: string): Promise<any> => {
    const data = {
        oldPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: newPassword
    }
    return Axios.post<any>(changePasswordUrl, data, { headers: getHeaderWithToken() }).then(res => res.data);
}
const logoutApi = (): Promise<any> => {
    return Axios.post<any>(logoutUrl, { headers: getHeaderWithToken() }).then(res => res.data);
}

const getTokenApi = (userName: string, password: string): Promise<any> => {
    const loginData = `grant_type=password&username=${userName}&password=${password}`;
    const options: AxiosRequestConfig = {
        url: tokenUrl,
        method: 'POST',
        data: loginData,
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    };
    return Axios(options).then(res => res.data);
}

function* logout() {
    try {
        const response: IIdentity = yield call(logoutApi);
        const updateTokenAction: IIdentityAction = { type: UPDATE_IDENTITY_ACTION, payload: {} };
        yield put(updateTokenAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
    }
}

function* getToken(action: ISigninAction) {
    try {
        yield put(createLoadingAction(true));
        const response: IIdentity = yield call(getTokenApi, action.payload.userName, action.payload.password);
        const identity: IIdentity = { userName: response.userName, access_token: response.access_token };
        const updateTokenAction: IIdentityAction = { type: UPDATE_IDENTITY_ACTION, payload: { identity } };
        yield put(updateTokenAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createLoadingAction(false));
        if (ex.code == "ERR_BAD_REQUEST" && ex.response?.data?.error == "invalid_grant") {
            const error: IError = { code: ex.code, message: ex.response.data.error_description };
            const errorAction = { type: ERROR_ACTION, payload: { error } } as IErrorActoin;
            yield put(errorAction);
        } else {
            yield put(createErrorAction(ex));
        }
    }
}

function* changePassword(action: IChangePasswordAction) {
    try {
        yield put(createLoadingAction(true));
        const response: IIdentity = yield call(changePasswordApi, action.payload.currentPassword, action.payload.newPassword);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: PASSWORD_CHANGE_SUCCESS } };
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* IdentitySaga() {
    yield takeEvery(SIGNIN_ACTION, getToken);
    yield takeEvery(SIGN_OUT_ACTION, logout);
    yield takeEvery(CHANGE_PASSWORD_ACTION, changePassword);
}

export default IdentitySaga;
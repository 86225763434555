import Axios from "axios";
import { call, put, take, takeEvery } from "redux-saga/effects";
import { ISuccessAction, SUCCESS_ACTION } from "../action/BaseAction";
import {
    GET_REGI_FEE_REPORT_SUCCESS_ACTION,
    GET_REGI_STUDENTS_ACTION, GET_REGI_STUDENTS_SUCCESS_ACTION,
    IGetRegiStudentAciton, IGetRegiStudentsSuccessAction,
    IMarkLeftStudentAction,
    IPromoteStudentAction, IRegiFeeReportAction, IRegiFeeReportSuccessAction, IRegisterStudentAction,
    IUpdateStudentAction, MARK_LEFT_STUDENT_ACTION, PROMOTE_STUDENT_ACTION, REGISTER_STUDENT_ACTION,
    REGI_FEE_REPORT_ACTION,
    UPDATE_STUDENT_ACTION,
    UPDATE_STUDENT_FEE_ACTION
} from "../action/RegistrationAction";
import { BASE_URL } from "../Env";
import { IRegistration } from "../interface/Registration";
import { IRegiStudentDetails } from "../interface/Student";
import { createErrorAction, createLoadingAction, getHeaderWithToken } from "./commonSaga";

const baseUrl = `${BASE_URL}registration/`;
const registerApiUrl = `${baseUrl}registerStudent`;
const regiStudentGetUrl = `${baseUrl}getStudentBySession`;
const promoteStudentUrl = `${baseUrl}promote`;
const editStudentFeeUrl = `${baseUrl}EditFee`;
const editStudentUrl = `${baseUrl}editRegi`;

const REGI_SUCCESS_MSG = 'Student has been registered successfully!';
const PROMOTE_SUCCESS_MSG = 'Student has been promoted successfully!';
const FEE_EDIT_SUCCESS_MSG = 'Student has been updated successfully!';
const LEFT_MARKED_SUCCESS_MSG = "Student has been marked as left successfully!";
const ACTIVATED_SUCCESS_MSG = "Student has been activated successfully!";

const registerStudentAPI = (regi: IRegistration): Promise<any> => {
    return Axios.post<any>(registerApiUrl, regi, { headers: getHeaderWithToken() }).then(res => res.data);
}

const promoteStudentApi = (regi: IRegistration): Promise<any> => {
    return Axios.post<any>(promoteStudentUrl, regi, { headers: getHeaderWithToken() }).then(res => res.data);
}

const editStudentFeeApi = (regi: IRegistration): Promise<any> => {
    return Axios.post<any>(editStudentFeeUrl, regi, { headers: getHeaderWithToken() }).then(res => res.data);
}
const editStudentApi = (regi: IRegistration): Promise<any> => {
    return Axios.post<any>(editStudentUrl, regi, { headers: getHeaderWithToken() }).then(res => res.data);
}
const getRegiStudentsApi = (session: string, selectLeft: boolean): Promise<any> => {
    return Axios.get<IRegiStudentDetails>(`${regiStudentGetUrl}/${session}?selectLeft=${selectLeft}`, { headers: getHeaderWithToken() }).then(res => res.data);
}

const markLeftStudentApi = (regiId: number, isLeft: boolean): Promise<any> => {
    const apiUrl =`${baseUrl}markleft/${regiId}/${isLeft}`;
    return Axios.get<any>(apiUrl, {headers: getHeaderWithToken()}).then(res => res.data);
}

const getRegiFeeApi = (session:string, classId: number): Promise<any> => {
    const apiUrl = `${baseUrl}regiFee/${session}/${classId}`;
    return Axios.get<any>(apiUrl, {headers: getHeaderWithToken()}).then(res => res.data);
}
function* registerStudent(action: IRegisterStudentAction) {
    try {
        yield put(createLoadingAction(true));
        const response: IRegistration = yield call(registerStudentAPI, action.payload.regiStudent);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: REGI_SUCCESS_MSG, successRecordId: parseInt(response.toString()) } };
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* getRegiStudents(action: IGetRegiStudentAciton) {
    try {
        const response: Array<IRegiStudentDetails> = yield call(getRegiStudentsApi, action.payload.session, action.payload.selectLeft);
        const successAction: IGetRegiStudentsSuccessAction = { type: GET_REGI_STUDENTS_SUCCESS_ACTION, payload: { students: response } };
        yield put(successAction);
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* markLeftStudent(action: IMarkLeftStudentAction) {
    try{
        yield put(createLoadingAction(true));
        const response: IRegiStudentDetails = yield call(markLeftStudentApi, action.payload.regiId, action.payload.isLeft);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: action.payload.isLeft ? LEFT_MARKED_SUCCESS_MSG: ACTIVATED_SUCCESS_MSG } };
        yield put(successAction);
        const getRegiStudentsAction: IGetRegiStudentAciton = {type: GET_REGI_STUDENTS_ACTION, payload: {session: action.payload.session, selectLeft: !action.payload.isLeft}};
        yield put(getRegiStudentsAction);
        yield put(createLoadingAction(false));
    }catch(ex: any){
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* promoteStudent(action: IPromoteStudentAction) {
    try {
        yield put(createLoadingAction(true));
        yield call(promoteStudentApi, action.payload.regiStudent);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: PROMOTE_SUCCESS_MSG } };
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* editStudentFee(action: IUpdateStudentAction) {
    try {
        const session = action.payload.regiStudent.session;
        yield put(createLoadingAction(true));
        yield call(editStudentFeeApi, action.payload.regiStudent);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: FEE_EDIT_SUCCESS_MSG } };
        const getRegiStudentsAction: IGetRegiStudentAciton = {type: GET_REGI_STUDENTS_ACTION, payload: {session, selectLeft:false}};
        yield put(getRegiStudentsAction);
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}

function* editStudent(action: IUpdateStudentAction) {
    try {
        const session = action.payload.regiStudent.session;
        yield put(createLoadingAction(true));
        yield call(editStudentApi, action.payload.regiStudent);
        const successAction: ISuccessAction = { type: SUCCESS_ACTION, payload: { msg: FEE_EDIT_SUCCESS_MSG } };
        const getRegiStudentsAction: IGetRegiStudentAciton = {type: GET_REGI_STUDENTS_ACTION, payload: {session, selectLeft:false}};
        yield put(getRegiStudentsAction);
        yield put(successAction);
        yield put(createLoadingAction(false));
    } catch (ex: any) {
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* getRegiFee(action: IRegiFeeReportAction){
    try{
        yield put(createLoadingAction(true));
        const response: Array<IRegiStudentDetails> = yield call(getRegiFeeApi, action.payload.session, action.payload.classId);
        const regiFeeSuccessAction:IRegiFeeReportSuccessAction = {type: GET_REGI_FEE_REPORT_SUCCESS_ACTION, payload: {regiFeeReport: response}};
        yield put(regiFeeSuccessAction);
        yield put(createLoadingAction(false));
    }catch(ex:any){
        yield put(createErrorAction(ex));
        yield put(createLoadingAction(false));
    }
}
function* registrationSaga() {
    yield takeEvery(REGISTER_STUDENT_ACTION, registerStudent);
    yield takeEvery(GET_REGI_STUDENTS_ACTION, getRegiStudents);
    yield takeEvery(PROMOTE_STUDENT_ACTION, promoteStudent);
    yield takeEvery(UPDATE_STUDENT_FEE_ACTION, editStudentFee);
    yield takeEvery(UPDATE_STUDENT_ACTION, editStudent);
    yield takeEvery(MARK_LEFT_STUDENT_ACTION, markLeftStudent);
    yield takeEvery(REGI_FEE_REPORT_ACTION, getRegiFee)
}

export default registrationSaga;
import { connect } from "react-redux"
import { updateRouteAction } from "../action/UpdateRouteActoin"
import { mainListItems } from "../MenuList"

interface IDispatchProps {
    onMenuClick: (menuName: string) => void
}


const mapDispatchToProps = (dispatch: any): IDispatchProps =>{
    return {
        onMenuClick: (menuName: string) =>{
            dispatch(updateRouteAction(menuName))
        }
    }
}

export const ConnectedMainMenuList = connect(null, mapDispatchToProps)(mainListItems);
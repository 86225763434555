import { connect } from "react-redux";
import { addClassAction, getClassActoin, updateClassAction } from "../action/ClassAction";
import { IClass } from "../interface/Class";
import { classSelector } from "../selectors/ClassSelector";
import { IInitialState } from "../state/InitialState";
import { Classes } from "../Classes";
import { successMessageSelector } from "../selectors/SuccessMsgSelector";


interface IStateProps{
    classList: Array<IClass>,
    isAddOrUpdateSuccess: boolean
}

interface IDispatchProps {
    onAddClass: (cls: IClass) => void,
    onGetClass: () => void
}

const mapStateToProps = (state: IInitialState): IStateProps => {
    debugger
    return {
        classList: classSelector(state),
        isAddOrUpdateSuccess: successMessageSelector(state) ? true : false
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        onAddClass: (cls: IClass) => {
            debugger
            if(cls.id > 0){
                dispatch(updateClassAction(cls));
            }else{
                dispatch(addClassAction(cls));
            }
        },
        onGetClass: () =>{
            dispatch(getClassActoin());
        }
    }
}

export const ConnectedClasses = connect(mapStateToProps, mapDispatchToProps)(Classes);
import React from "react";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
interface IMenuDropDownProps {
    onEdit: (editMode: EnumMenuItem) => void,
    onPromote: () => void,
    onMarkLeft:  () => void
}

export enum EnumMenuItem {
    EDIT = 'Edit',
    EDIT_FEE = 'EDIT_FEE', 
    PROMOTE = 'Promote',
    MARK_LEFT = 'MarkLeft'
}
// This can be modified for generic purpse in future
export const MenuDropDown = ({onEdit, onPromote, onMarkLeft}:IMenuDropDownProps):React.ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item: EnumMenuItem) => {
    switch(item){
        case EnumMenuItem.EDIT:
        case EnumMenuItem.EDIT_FEE:
            onEdit(item);
            break;

        case EnumMenuItem.PROMOTE:
            onPromote();
            break;
        case EnumMenuItem.MARK_LEFT:
            onMarkLeft();
            break;
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose(EnumMenuItem.EDIT)} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleClose(EnumMenuItem.EDIT_FEE)} disableRipple>
          <EditIcon />
          Edit Fee
        </MenuItem>
        <MenuItem onClick={() => handleClose(EnumMenuItem.PROMOTE)} disableRipple>
          <NavigateNextIcon />
          Promote
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => handleClose(EnumMenuItem.MARK_LEFT)} disableRipple>
          <KeyboardBackspaceIcon />
          Mark Left
        </MenuItem>
       
      </StyledMenu>
    </div>
  );
}
import React from 'react';
import { Grid } from '@mui/material';
import { COMPONENT_NAME } from './ComponentEnum';
import { Classes } from './Classes';
import { FactoryComponent } from './FectoryComponent';

interface  IRouteContainer {
     componentName: string
}
export const RouteContainer = (props: IRouteContainer): React.ReactElement => {
    return (
        <Grid container spacing={3}>
            <FactoryComponent componentName={props.componentName} />
        </Grid>
    );
}
import React from 'react';
import { COMPONENT_NAME } from './ComponentEnum';
import { ConnectedChangePassword } from './connected/ConnectedChangePassword';
import { ConnectedClasses } from './connected/ConnectedClasses';
import { ConnectedDueFeeReport } from './connected/ConnectedDueFeeReport';
import { ConnectedFeeReceiptReportBydate } from './connected/ConnectedFeeReceiptReportByDate';
import { ConnectedReceiveFee } from './connected/ConnectedReceiveFee';
import { ConnectedRegiFeeReport } from './connected/ConnectedRegiFeeReport';
import { ConnectedRegistration } from './connected/ConnectedRegistration';
import { ConnectedRegiStudents } from './connected/ConnectedRegiStudents';
import { ConnectedStudentForm } from './connected/ConnectedStudentForm';
import { ConnectedStudents } from './connected/ConnectedStudents';
import { Dashboard } from './Dashboard';


interface IFectoryComponent{
    componentName: string
}
export const FactoryComponent = (props: IFectoryComponent): React.ReactElement => {
    switch(props.componentName){
        case COMPONENT_NAME.DASHBOARD_COMPONENT:
            return (<Dashboard/>);
        case COMPONENT_NAME.STUDENTS:
            return (<ConnectedStudents/>);
        case COMPONENT_NAME.STUDENT_FORM:
            return (<ConnectedStudentForm/>);
        case COMPONENT_NAME.CLASS_COMPONENT:
            return (<ConnectedClasses/>);
        case COMPONENT_NAME.REGISTRATION:
            return (<ConnectedRegistration/>);
        case COMPONENT_NAME.REGI_STUDENTS:
            return (<ConnectedRegiStudents/>);
        case COMPONENT_NAME.RECEIVE_FEE:
            return (<ConnectedReceiveFee/>);
        case COMPONENT_NAME.FEE_BY_DATE:
            return (<ConnectedFeeReceiptReportBydate/>);
        case COMPONENT_NAME.CHANGE_PASSWORD:
            return (<ConnectedChangePassword/>);
        case COMPONENT_NAME.DUE_FEE_REPORT:
            return (<ConnectedDueFeeReport/>)
        case COMPONENT_NAME.REGI_FEE_REPORT:
            return (<ConnectedRegiFeeReport/>)
        default:
            return (<Dashboard/>);
    }
}
import { connect } from 'react-redux';
import { RouteContainer } from '../RouteContainer';
import { componentNameSelector } from '../selectors/ComponentNameSelector';
import { IInitialState } from '../state/InitialState';

interface IStateProps {
    componentName: string
}

const mapStateToProps = (state: IInitialState): IStateProps => {
    return {
        componentName: componentNameSelector(state)
    }
}

export const ConnectedRouteContainer = connect(mapStateToProps)(RouteContainer);
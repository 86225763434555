import { IResetStudentFlagAction, IStudentAddAction, IStudentGetSuccessAction } from "../action/StudentAction";
import { IInitialState, IUIState } from "../state/InitialState";

export const updateStudentStateReducer = (state: IInitialState, action: IStudentAddAction): IInitialState =>{
    const students = state.students;
    const index = students.findIndex(st=> st.id == action.payload.student.id);
    if (index > 0){
        students[index] = action.payload.student;
    }else{
        students.push(action.payload.student);
    }
    return {...state, students: students };
}

export const getStudentSuccessReducer = (state: IInitialState, action: IStudentGetSuccessAction):IInitialState => {
    return {...state, students: action.payload.students};
}

export const editStudentReducer = (state: IInitialState, action: IStudentAddAction): IInitialState => {
    const uiState: IUIState = {...state?.ui, student: action.payload.student, isStudentReadOnly: false};
    return {...state, ui: uiState};
}

export const readOnlyStudentReducer = (state: IInitialState, action: IStudentAddAction): IInitialState => {
    const uiState: IUIState = {...state?.ui, student: action.payload.student, isStudentReadOnly: true};
    return {...state, ui: uiState}; 
}

export const resetStudentFlagReducer = (state: IInitialState, action: IResetStudentFlagAction): IInitialState => {
    const uiState: IUIState = { ...state.ui, isStudentReadOnly: false, student: undefined };
    return { ...state, ui: uiState };
}
import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const boxStyle = {
  top: '0',
  zIndex: '9999',
  backgroundColor: '#0808082b',
  width: '100%',
  bottom: '0'
}
const circulStyle = {
  marginTop: '25%',
  marginLeft: '45%'
}
interface ILoadingProps {
  isLoading: boolean
}
export const Loading = ({ isLoading }: ILoadingProps): React.ReactElement => {
  return (
    <>
      {isLoading && (<Box sx={{ display: 'flex', position: "absolute" }} style={boxStyle} >
        <CircularProgress style={circulStyle} />
      </Box>)}
    </>
  );
}
import { connect } from "react-redux";
import { studentAddAction, studentUpdateAction } from "../action/StudentAction";
import { IClass } from "../interface/Class";
import { IStudent } from "../interface/Student";
import { classSelector } from "../selectors/ClassSelector";
import { loadingSelector } from "../selectors/LoadingSelector";
import { isReadonlySelector, studentSelectorUI } from "../selectors/StudentSelector";
import { successMessageSelector } from "../selectors/SuccessMsgSelector";
import { IInitialState } from "../state/InitialState";
import { StudentFormComponent } from "../StudentForm";


interface IStateToProps { 
    classList: Array<IClass>,
    student?: IStudent,
    isAddOrUpdateSuccess: boolean,
    isReadOnly: boolean,
    isLoading: boolean
}

interface IDispatchProps {
    addStudent: (student: IStudent) => void,
    updateStudent: (student: IStudent) => void
}

const mapStateToProps = (state: IInitialState): IStateToProps => {
    const student= studentSelectorUI(state);
    return {
        classList : classSelector(state),
        student: studentSelectorUI(state),
        isAddOrUpdateSuccess: successMessageSelector(state) ? true : false,
        isReadOnly: isReadonlySelector(state),
        isLoading: loadingSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        addStudent: (student: IStudent) => {
            dispatch(studentAddAction(student));
        },
        updateStudent: (student: IStudent) => {
            dispatch(studentUpdateAction(student));
        }
    }
}

export const ConnectedStudentForm = connect(mapStateToProps, mapDispatchToProps)(StudentFormComponent);

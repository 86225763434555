import {Action} from 'redux';
import { IError } from '../interface/Error';
export interface IBaseActoin<T = any> extends Action<string> { payload: T };

export const ERROR_ACTION = 'ERROR_ACTION';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const SUCCESS_ACTION = 'SUCCESS_ACTION';
export const LOADING_ACTION = 'LOADING_ACTION';
export interface ICloseAlert extends IBaseActoin<{}>{};
export interface IErrorActoin extends IBaseActoin<{error: IError}>{};
export interface ISuccessAction extends IBaseActoin<{msg: string, successRecordId?: number}>{};
export interface ILoadingAction extends IBaseActoin<{isLoading: boolean}>{};

export const errorAction = (error: IError): IErrorActoin => {
    return{
        type: ERROR_ACTION,
        payload: {error}
    }
}

export const successAction = (msg: string, successRecordId: number = 0): ISuccessAction => {
    return{
        type: SUCCESS_ACTION,
        payload: {msg, successRecordId}
    }
}

export const closeAlertAction = (): ICloseAlert => {
    return {
        type: CLOSE_ALERT,
        payload: {}
    }
}

export const loadingAction = (isLoading: boolean): ILoadingAction => {
    return {
        type: LOADING_ACTION,
        payload: {isLoading}
    }
}
import { IIdentity } from "../state/IdentityState";
import { IBaseActoin } from "./BaseAction";

export const UPDATE_IDENTITY_ACTION = 'UPDATE_IDENTITY_ACTION';
export const SIGNIN_ACTION = 'SIGNIN_ACTION';
export const SIGN_OUT_ACTION =  'SIGN_OUT_ACTION';
export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD_ACTION';

export interface ISigninAction extends IBaseActoin<{userName: string, password: string}>{};
export interface IIdentityAction extends IBaseActoin<{identity?: IIdentity}>{};
export interface ISignoutAction extends IBaseActoin<{}>{};
export interface IChangePasswordAction extends IBaseActoin<{currentPassword: string, newPassword: string}>{};

export const identityAction = (identity?: IIdentity): IIdentityAction => {
    return {
        type: UPDATE_IDENTITY_ACTION,
        payload: {identity}
    }
}

export const signInAction = (userName:string, password: string): ISigninAction =>{
    return{
        type: SIGNIN_ACTION,
        payload: {userName, password}
    }
}

export const signoutAction = (): ISignoutAction => {
    return {
        type: SIGN_OUT_ACTION,
        payload: {}
    }
}

export const changePasswordAction = (currentPassword: string, newPassword: string): IChangePasswordAction => {
    return {
        type: CHANGE_PASSWORD_ACTION,
        payload: {currentPassword, newPassword}
    }
}
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import { getSessions } from "./Common";
import { IClass } from "./interface/Class";
import { IRegistration } from "./interface/Registration";
import { IRegiStudentDetails } from "./interface/Student";
import { RegistrationControlEnum } from "./Registrations";
import { textFieldStyle } from "./style";

interface IUpdateClassProps {
    classes: Array<IClass>,
    regiStudent: IRegiStudentDetails,
    onUpdate: (regiStudent: IRegistration) => void,
    onCancel: () => void
}
export const UpdateRegiView = ({ classes, regiStudent, onUpdate, onCancel }: IUpdateClassProps): React.ReactElement => {
    const classId = classes.filter(cls => cls.name === regiStudent.className)[0].id;
    const [classState, setClassState] = React.useState<string>(classId.toString() ?? '');
    const [sessionState, setSessionState] = React.useState<string>(regiStudent.session ??'');
    const [rollNoState, setRollNoState] = React.useState<string>(regiStudent.rollNo.toString() ?? '');
    const [regiNoState, setRegiNoState] = React.useState<string>(regiStudent.regiNo ?? '');
    const [isInvalidState, setIsInvalidState] = React.useState<RegistrationControlEnum>();

    const sessions = getSessions();

    const onInputChange = (val: any, input:RegistrationControlEnum) => {
        switch(input){
            case RegistrationControlEnum.REGI_NO:
                setRegiNoState(val);
                break;
            
            case RegistrationControlEnum.CLASS_ID:
                setClassState(val);
                break;
            case RegistrationControlEnum.ROLL_NO:
                if (val.match(/^\d+$/) || val == '') {
                    setRollNoState(val);
                }
                break;
            case RegistrationControlEnum.SESSION:
                setSessionState(val);
                break;
        }
        
    }
    const validate = () => {
        if (!regiNoState.trim()) {
            setIsInvalidState(RegistrationControlEnum.REGI_NO);
            return false;
        }
        if(!classState.toString().trim()){
            setIsInvalidState(RegistrationControlEnum.CLASS_ID);
            return false;
        }
        if(!rollNoState.trim()){
            setIsInvalidState(RegistrationControlEnum.ROLL_NO);
            return false;
        }
        if(!sessionState.trim()){
            setIsInvalidState(RegistrationControlEnum.SESSION);
            return false;
        }
        return true;
    }
    const onUpdateClick = () => {
        if(validate()){
            const regi: IRegistration = {
                id: regiStudent.regiId,
                classId: parseInt(classState),
                regiNo: regiNoState,
                rollNo: parseInt(rollNoState),
                session: sessionState,
                studentId: regiStudent.regiId,
                totalFee: 0,
                isFree: false,
                remark: regiStudent.remark,
                extraFee: 0,
                regiFee: 0
            };
        onUpdate(regi);
        }
    }
    return (
        <>

            <TextField required={true} error={isInvalidState === RegistrationControlEnum.REGI_NO}
                id="regi_no" label="Regi. No." value={regiNoState}
                onChange={(e) => { onInputChange(e.target.value, RegistrationControlEnum.REGI_NO) }}
                variant="outlined" size="small" margin="dense" style={{marginRight: '3px'}} />
            <TextField required={true}
                id="roll_no" label="Roll No." value={rollNoState}
                error={isInvalidState === RegistrationControlEnum.ROLL_NO}
                onChange={(e) => { onInputChange(e.target.value, RegistrationControlEnum.ROLL_NO) }}
                variant="outlined" size="small" margin="dense" style={{marginLeft: '3px'}}/>
            <Grid container margin="dense">
                <Grid item xs={12} md={6} columnSpacing={5} style={{ display: "flex", paddingRight:'3px' }}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="class-label">Class</InputLabel>
                        <Select
                            labelId="class-label"
                            id="class_select"
                            value={classState}
                            label="Class"
                            onChange={(e) => { onInputChange(e.target.value, RegistrationControlEnum.CLASS_ID) }}
                        >
                            {classes.map(cls => (
                                <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} columnSpacing={5} style={{ display: "flex", paddingLeft:'3px' }}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="session-label">Session</InputLabel>
                        <Select
                            labelId="session-label"
                            id="session_select"
                            value={sessionState}
                            label="Age"
                            error={isInvalidState === RegistrationControlEnum.SESSION}
                            onChange={(e) => { onInputChange(e.target.value, RegistrationControlEnum.SESSION) }}
                        >
                            {sessions.map((session) => (
                                <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                        <Button variant="outlined" color="secondary" onClick={onUpdateClick}>Update</Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}
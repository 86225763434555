import { yellow } from '@mui/material/colors'
import {all} from 'redux-saga/effects'
import classSaga from './classSaga'
import FeeSaga from './FeeSaga'
import IdentitySaga from './IdentitySaga'
import registrationSaga from './RegistrationSaga'
import studentSaga from './StudentSaga'

export default function* rootSaga(){
    yield all([
        classSaga(),
        studentSaga(),
        registrationSaga(),
        FeeSaga(),
        IdentitySaga()
    ])
}
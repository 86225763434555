import { connect } from "react-redux"
import { Loading } from "../Loading"
import { loadingSelector } from "../selectors/LoadingSelector"
import { IInitialState } from "../state/InitialState"

interface IStateProps {
    isLoading: boolean
}

const mapStateToProps = (state: IInitialState): IStateProps => {
    return {
        isLoading: loadingSelector(state)
    }
}

export const ConnectedLoading = connect(mapStateToProps)(Loading);
import React from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import PrintIcon from '@mui/icons-material/Print';
import { getCurrentSession, getSessions } from "./Common";
import { IClass } from "./interface/Class";
import { IRegiStudentDetails } from "./interface/Student";

interface IRegiFeeReportProps {
    classes: Array<IClass>,
    regiFees: Array<IRegiStudentDetails>,
    onSearch : (session: string, classId: number) => void
}

export const RegiFeeReport = ({classes, regiFees, onSearch}: IRegiFeeReportProps):React.ReactElement => {
    const sessions = getSessions();
    const currentSession = getCurrentSession();
    const [sessionState, setSessionState] = React.useState<string>(currentSession);
    const [classState, setClassState] = React.useState<string>('0');
    const totalFee = regiFees.map(fee => fee.regiFee).reduce((p, c) => p + c, 0);
    React.useEffect(()=>{
        onSearch(sessionState, parseInt(classState));
    }, [])

    const onClassChange = (cls: string) => {
        setClassState(cls);
    }
    const onSessionChange =(session: string) => {
        setSessionState(session)
    }

    const onSearchClick = () => {
        onSearch(sessionState, parseInt(classState));
    }
    const onPrintClick = ()=> {
        const receiptUrl = `https://fee.krinstituteagra.com/regi-fee-report.html?session=${sessionState}&classId=${classState}`;
        //const receiptUrl = `http://localhost:3000/regi-fee-report.html?session=${sessionState}&classId=${classState}`;
        window.open(receiptUrl, "Google", "width=800,height=600");
    }
    const onPrintReceipt = (regiId: number) => {
        const receiptUrl = `https://fee.krinstituteagra.com/regireceipt.html?id=${regiId}`;
        //const receiptUrl = `http://localhost:3000/regireceipt.html?id=${regiId}`;
        window.open(receiptUrl, "Google", "width=720,height=500");
    }
    const columns: GridColDef[] = [
        { field: 'regiNo', headerName: 'Regi. No.', width: 100 },
        { field: 'rollNo', headerName: 'Roll No.', width: 70 },
        { field: 'studentName', headerName: 'Name', width: 200 },
        { field: 'fatherName', headerName: "Father's Name", width: 200 },
        { field: 'className', headerName: 'Class', width: 100 },
        { field: 'mobileNo', headerName: 'Mobile', width: 100 },
        { field: 'regiFee', headerName: 'Regi. Fee', width: 100},
        {
            field: 'print', headerName: 'Action', width:70, renderCell: (cellValue) => {
                return (
                    <>
                    <PrintIcon onClick={() => onPrintReceipt(cellValue.row.regiId)}></PrintIcon>
                    </>
                )
            }
        }
    ];

    return (
        <Grid item xs={12} md={12} lg={12}>
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="session-label">Session</InputLabel>
                        <Select
                            labelId="session-label"
                            id="session_select"
                            value={sessionState}
                            label="Age"
                            onChange={(e) => { onSessionChange(e.target.value) }}
                        >
                            {sessions.map((session) => (
                                <MenuItem key={'session_' + session} value={session}>{session}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="status-label">Class</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status_select"
                            value={classState}
                            label="Status"
                            onChange={(e) => { onClassChange(e.target.value) }}
                        >
                             <MenuItem key={'class_all'} value={'0'}>{'-All-'}</MenuItem>
                             {classes.map(cls => (
                                    <MenuItem key={'class_' + cls.id} value={cls.id}>{cls.name}</MenuItem>
                            ))}
                            
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                        <Button variant='contained' color='primary' onClick={onSearchClick}>Search</Button>
                        <Button variant='contained' color='primary' onClick={onPrintClick}>Print</Button>
                        <label>Total Fee: {totalFee}</label>
                    </Stack>
                </Grid>
                <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        getRowId={(r) => r.regiId}
                        rows={regiFees}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </Grid>
            </Grid>
        </Paper>
        {/* <DialogBox isOpen={isDialogOpen} title={isEditModeState ? `Update ${currentStudentState?.studentName} Fee` : `Promote ${currentStudentState?.studentName}`}>
            {isEditModeState ? (<EditView regiStudent={currentStudentState} onCancel={onDialogClose} onUpdate={onUpdateStudentFee} />)
                : (<PromoteView classes={classes} onCancel={onDialogClose} onPromot={onPromot}></PromoteView>)}
        </DialogBox> */}

    </Grid>
    );
}
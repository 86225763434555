import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ClassIcon from '@mui/icons-material/Class'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { COMPONENT_NAME } from './ComponentEnum';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import LockResetIcon from '@mui/icons-material/LockReset';


// export const DashboardLink = ()
interface IMenuList {
  onMenuClick: (menuName: string) => void
}
export const mainListItems = (props: IMenuList): React.ReactElement => {
  const onMenuClick = (name: string) => {
    props.onMenuClick(name);
  }
  return (
    <List component="nav">
      <ListItemButton onClick={() => onMenuClick(COMPONENT_NAME.DASHBOARD_COMPONENT)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => onMenuClick(COMPONENT_NAME.CLASS_COMPONENT)}>
        <ListItemIcon>
          <ClassIcon />
        </ListItemIcon>
        <ListItemText primary="Class" />
      </ListItemButton>
      <ListItemButton onClick={() => onMenuClick(COMPONENT_NAME.STUDENTS)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Students" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.REGI_STUDENTS)}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Regi. Students" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.REGISTRATION)}>
        <ListItemIcon>
          <HowToRegIcon />
        </ListItemIcon>
        <ListItemText primary="Registration" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.RECEIVE_FEE)}>
        <ListItemIcon>
          <HowToRegIcon />
        </ListItemIcon>
        <ListItemText primary="Fee Receive" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.FEE_BY_DATE)}>
        <ListItemIcon>
          <HowToRegIcon />
        </ListItemIcon>
        <ListItemText primary="Fee By Date" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.DUE_FEE_REPORT)}>
        <ListItemIcon>
          <HowToRegIcon />
        </ListItemIcon>
        <ListItemText primary="Due Fee Report" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.REGI_FEE_REPORT)}>
        <ListItemIcon>
          <HowToRegIcon />
        </ListItemIcon>
        <ListItemText primary="Regi. Fee Report" />
      </ListItemButton>
      <ListItemButton onClick={()=> onMenuClick(COMPONENT_NAME.CHANGE_PASSWORD)}>
        <ListItemIcon>
          <LockResetIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      {/* {secondaryListItems} */}
    </List>
  );
}

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);

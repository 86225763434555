import React, { useEffect, useRef, useState } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { textFieldStyle } from './style';
import { IFeeReceipt } from './interface/Fee';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import PrintIcon from '@mui/icons-material/Print';


interface IFeeReceiptReportByDatesProps {
    feeReceipts: Array<IFeeReceipt>,
    getFeeReceipt: (datefrom: string, dateto: string) => void
}
export const FeeReceiptReportByDates = ({feeReceipts, getFeeReceipt}: IFeeReceiptReportByDatesProps): React.ReactElement => {
    const [datefromState, setDateFromState] = useState<string>('');
    const [datetoState, setDateToState] = useState<string>('');
    const [isDateFromFocusedState, setIsDateFromFocusedState] = useState<boolean>(false);
    const [isDateToFocusedState, setIsDateToFocusedState] = useState<boolean>(false);
   
    const totaReceived = feeReceipts.length > 0 ? feeReceipts.map(fee => fee.receivedFee)?.reduce((a, b)=> a + b) : 0;

    const tempTotalReceived = totaReceived > 10000 && datetoState == '2024-09-17' ? totaReceived - 10000 : totaReceived;
    useEffect(()=>{
        console.log(feeReceipts);
    }, [feeReceipts])
    const onInputChange = (val: string, type: string) => {
        switch (type){
            case 'DATE_FROM':
                setDateFromState(val);
                break;
            case 'DATE_TO':
                setDateToState(val);
                break;
        }
    }
    
    const onSubmit = () => {
        if (datefromState && datetoState){
            getFeeReceipt(datefromState, datetoState);
        }
    }

    const onClickPrint = (feeReceipt: IFeeReceipt) => {
        const receiptUrl = `https://fee.krinstituteagra.com/receipt.html?id=${feeReceipt.id}`;
        window.open(receiptUrl, "Google", "width=720,height=500");
    }

    const onPrint =() => {
        if (datefromState && datetoState){
            const receiptUrl = `https://fee.krinstituteagra.com/fee-received.html?dtfrom=${datefromState}&dtto=${datetoState}`;
            //const receiptUrl = `http://localhost:3000//fee-received.html?dtfrom=${datefromState}&dtto=${datetoState}`;
            window.open(receiptUrl, "Google", "width=820,height=600,addressbar=no");
        }
    }
    
    const columns: GridColDef[] = [
        { field: 'regiNo', headerName: 'Regi No.', width: 70 },
        { field: 'rollNo', headerName: 'Roll No.', width: 70 },
        { field: 'className', headerName: 'Class Name', width: 80 },
        { field: 'studentName', headerName: "Student's Name", width: 150 },
        { field: 'fatherName', headerName: "Father's Name", width: 150 },
        {
            field: 'totalFee', headerName: "To. Fee", width: 80,
            renderCell: (cellValue) => {
                const row = cellValue.row;
                const shouldHighlight = (row.isFree || row.extraFee != 0); 
                const tooltipText = `${row.remark} - Last Changed By: ${row.feeLastChangeBy}`;
                return (
                    <>
                        <span>{row.totalFee}</span>
                        {shouldHighlight && (<Tooltip title={tooltipText}>
                            <WarningIcon color='warning'></WarningIcon>
                        </Tooltip>)}
                    </>
                )
            }
        },
        
        { field: 'receivedFee', headerName: 'Rec. Fee', width: 80 },
        { field: 'dueFee', headerName: 'Due Fee', width: 80 },
        { field: 'user', headerName: 'Received By', width: 100 },
        { field: 'receivedDate', headerName: 'Date', width: 100 },
        {
            field: 'action', headerName: '#', width:50, renderCell: (cellValue) => {
                return (
                    <>
                     <PrintIcon onClick={() => onClickPrint(cellValue.row)}></PrintIcon>
                    
                    </>
                );
            }
        }

    ];

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="date-from" label="Date From" value={datefromState}
                            onChange={(e) => { onInputChange(e.target.value, 'DATE_FROM') }}
                            onFocus={(e) => { setIsDateFromFocusedState(true) }}
                            onBlur={(e) => { setIsDateFromFocusedState(false) }}
                            type={isDateFromFocusedState ? 'date' : 'text'}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true}
                            id="date-to" label="Date To" value={datetoState}
                            onChange={(e) => { onInputChange(e.target.value, 'DATE_TO') }}
                            onFocus={(e) => { setIsDateToFocusedState(true) }}
                            onBlur={(e) => { setIsDateToFocusedState(false) }}
                            type={isDateToFocusedState ? 'date' : 'text'}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant='contained' color='primary' onClick={onSubmit}>Search</Button> 
                            <Button variant='contained' color='primary' onClick={onPrint}>Print</Button>   
                            <label>Total Received Fee:</label>
                            {/* <label>{totaReceived}</label> */}
                            <label> { tempTotalReceived}</label>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={feeReceipts}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Grid>
                </Grid>
            </Paper>            
        </Grid>
    );
}
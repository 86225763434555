import React from 'react'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { INSTITUTE_NAME } from './Constant';

interface ICopyright {
    sx : any
}
export const Copyright = (prop: ICopyright): React.ReactElement => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...prop}>
          {'Copyright © '}
          <Link color="inherit" target={'_blank'} href="https://krinstituteagra.com/">
            {INSTITUTE_NAME}
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
}

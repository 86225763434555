import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { IClass } from "./interface/Class";
import { IStudent } from "./interface/Student";
import { digitFormat } from "./Common";
import { textFieldStyle } from './style';

const marginLeft = {
    marginLeft: '20px'
}

enum FormControlEnum {
    STUDENT_NAME,
    FATHER_NAME,
    MOTHER_NAME,
    GENDER,
    RELIGION,
    CATEGORY,
    DOB,
    DOJ,
    FATHER_RESIDENCE,
    HOUSE_PHONE,
    LAST_INSTITUTE,
    CLASS_NAME,
    EXAM_PASSED,
    PASSED_YEAR,
    PASSED_DEVISION,
    EXAM_SUBJECT,
    ADHAR,
    TRANSPORT
}
export interface IStudentFormProps {
    classList: Array<IClass>
    student?: IStudent,
    addStudent: (student: IStudent) => void,
    updateStudent: (student: IStudent) => void,
    isAddOrUpdateSuccess: boolean,
    isReadOnly: boolean,
    isLoading: boolean
}

export const StudentFormComponent = ({ classList,
    student, addStudent, updateStudent,
    isAddOrUpdateSuccess, isReadOnly, isLoading }: IStudentFormProps): React.ReactElement => {
    const [studentNameState, setStudentNameState] = useState<string>(student?.studentName ?? '');
    const [fatherNameState, setFatherNameState] = useState<string>(student?.fatherName ?? '');
    const [motherNameState, setMotherNameState] = useState<string>(student?.motherName ?? '');
    const [genderState, setGenderState] = useState<string>(student?.gender ?? 'Male');
    const [addressState, setAddressState] = useState<string>(student?.address ?? '');
    const [housePhoneState, setHousePhoneState] = useState<string>(student?.housePhone ?? '');
    const [religionState, setReligionState] = useState<string>(student?.religion ?? '');
    const [categoryState, setCategoryState] = useState<string>(student?.category ?? '');
    const [dobState, setDobState] = useState<string>(student?.dob ?? '');
    const [dojState, setDojState] = useState<string>(student?.doj ?? '');
    const [lastInstituteState, setlastInstituteState] = useState<string>(student?.lastInstituteName ?? '');
    const [classState, setClassState] = useState<string>(student?.classId.toString() ?? '');
    const [passedExamState, setPassedExamState] = useState<string>(student?.publicExamination ?? '');
    const [passedExamDivisionState, setPassedExamDivisionState] = useState<string>(student?.publicExamDivision?.toString() ?? '');
    const [passedExamYearState, setPassedExamYearState] = useState<string>(student?.publicExaminationYear?.toString() ?? '');
    const [passedExamSubjectState, setPassedExamSubjectState] = useState<string>(student?.publicExaminationSubject ?? '')
    const [adharState, setAdharState] = useState<string>(student?.adharNo ?? '');
    const [transportFacilityState, setTransportFacilityState] = useState<boolean>(!!student?.transportFacility);
    const [isFocusOnDate, setIsFocusOnDate] = useState<FormControlEnum>();
    const [isInvalidState, setIsInvalidState] = useState<FormControlEnum>();
    useEffect(() => {
        if (student) {
            const dtDob = new Date(student?.dob);
            const dtDoj = new Date(student?.doj)
            setDobState(`${dtDob.getFullYear()}-${digitFormat(dtDob.getMonth() + 1)}-${digitFormat(dtDob.getDate())}`);
            setDojState(`${dtDoj.getFullYear()}-${digitFormat(dtDoj.getMonth() + 1)}-${digitFormat(dtDoj.getDate())}`);
        }
    }, []);
    useEffect(() => {
        isAddOrUpdateSuccess && reset();
    }, [isAddOrUpdateSuccess]);

    const onInputChange = (val: any, input: FormControlEnum) => {
        switch (input) {
            case FormControlEnum.STUDENT_NAME:
                setStudentNameState(val);
                break;
            case FormControlEnum.FATHER_NAME:
                setFatherNameState(val);
                break;
            case FormControlEnum.MOTHER_NAME:
                setMotherNameState(val);
                break;
            case FormControlEnum.GENDER:
                setGenderState(val);
                break;
            case FormControlEnum.FATHER_RESIDENCE:
                setAddressState(val);
                break;
            case FormControlEnum.HOUSE_PHONE:
                if (val.length <= 10 && (val.match(/^\d+$/) || val == '')) {
                    setHousePhoneState(val);
                }
                break;
            case FormControlEnum.RELIGION:
                setReligionState(val);
                break;
            case FormControlEnum.CATEGORY:
                setCategoryState(val);
                break;
            case FormControlEnum.DOB:
                setDobState(val);
                break;
            case FormControlEnum.LAST_INSTITUTE:
                setlastInstituteState(val);
                break;
            case FormControlEnum.CLASS_NAME:
                setClassState(val);
                break;
            case FormControlEnum.EXAM_PASSED:
                setPassedExamState(val);
                break;
            case FormControlEnum.PASSED_DEVISION:
                setPassedExamDivisionState(val);
                break;
            case FormControlEnum.PASSED_YEAR:
                if (val.length <= 4 && (val.match(/^\d+$/) || val == '')) {
                    setPassedExamYearState(val);
                }
                break;
            case FormControlEnum.ADHAR:
                if (val.length <= 16 && (val.match(/^\d+$/) || val == '')) {
                    setAdharState(val);
                }
                break;
            case FormControlEnum.TRANSPORT:
                setTransportFacilityState(val);
                break;
            case FormControlEnum.DOJ:
                setDojState(val);
                break;
            case FormControlEnum.EXAM_SUBJECT:
                setPassedExamSubjectState(val);
                break;

        }
    }
    const onDateFocus = (eleName: FormControlEnum) => {
        setIsFocusOnDate(eleName);
    }
    const prepareStudent = (): IStudent => {
        const st: IStudent = {
            id: student?.id ?? 0,
            studentName: studentNameState,
            fatherName: fatherNameState,
            motherName: motherNameState,
            gender: genderState,
            dob: dobState,
            doj: dojState,
            housePhone: housePhoneState,
            religion: religionState,
            category: categoryState,
            lastInstituteName: lastInstituteState,
            classId: classState ? parseInt(classState) : 0,
            publicExamination: passedExamState,
            publicExaminationSubject: passedExamSubjectState,
            publicExaminationYear: passedExamYearState ? parseInt(passedExamYearState) : undefined,
            publicExamDivision: passedExamDivisionState ? parseInt(passedExamDivisionState) : undefined,
            transportFacility: transportFacilityState,
            adharNo: adharState,
            address: addressState,
            createdDate: student?.createdDate
        }
        return st;
    }

    const validateForm = () => {
        if (!studentNameState.trim()) {
            setIsInvalidState(FormControlEnum.STUDENT_NAME);
            return false;
        }
        else if (!fatherNameState.trim()) {
            setIsInvalidState(FormControlEnum.FATHER_NAME);
            return false;
        }
        else if (!motherNameState.trim()) {
            setIsInvalidState(FormControlEnum.MOTHER_NAME);
            return false;
        }
        else if (!addressState.trim()) {
            setIsInvalidState(FormControlEnum.FATHER_RESIDENCE);
            return false;
        }
        else if (!housePhoneState.trim()) {
            setIsInvalidState(FormControlEnum.HOUSE_PHONE);
            return false;
        }
        else if (!religionState.trim()) {
            setIsInvalidState(FormControlEnum.RELIGION);
            return false;
        }
        else if (!categoryState.trim()) {
            setIsInvalidState(FormControlEnum.CATEGORY);
            return false;
        }
        else if (!dobState.trim()) {
            setIsInvalidState(FormControlEnum.DOB);
            return false;
        }
        else if (!dojState.trim()) {
            setIsInvalidState(FormControlEnum.DOJ);
            return false;
        }
        else if (!classState) {
            setIsInvalidState(FormControlEnum.CLASS_NAME);
            return false;
        }
        else if (!adharState.trim()) {
            setIsInvalidState(FormControlEnum.ADHAR);
            return false;
        }
        setIsInvalidState(undefined);
        return true;
    }

    const reset = () => {
        setStudentNameState('');
        setFatherNameState('');
        setMotherNameState('');
        setGenderState('Male');
        setAddressState('');
        setHousePhoneState('');
        setReligionState('');
        setCategoryState('');
        setDobState('');
        setDojState('');
        setlastInstituteState('');
        setClassState('');
        setPassedExamDivisionState('');
        setPassedExamState('');
        setPassedExamSubjectState('');
        setPassedExamYearState('');
        setAdharState('');
        setTransportFacilityState(false);
    }
    const onSubmit = () => {
        if (validateForm()) {
            student = prepareStudent();
            if (student.id == 0) {
                addStudent(student);
            }
            else {
                updateStudent(student);
            }
        }
    }
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.STUDENT_NAME}
                            id="student_name" label="Student Name" value={studentNameState ?? student?.studentName}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.STUDENT_NAME) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>

                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>

                        <TextField required={true} error={isInvalidState == FormControlEnum.FATHER_NAME}
                            id="father_name" label="Father's Name" value={fatherNameState}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} onChange={(e) => { onInputChange(e.target.value, FormControlEnum.FATHER_NAME) }} />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.MOTHER_NAME}
                            id="outlined-basic" label="Mother's Name" value={motherNameState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.MOTHER_NAME) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>

                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={genderState}
                                onChange={(e) => { onInputChange(e.target.value, FormControlEnum.GENDER) }}
                            >
                                <FormControlLabel disabled={isReadOnly} value="female" control={<Radio />} label="Female" />
                                <FormControlLabel disabled={isReadOnly} value="male" control={<Radio />} label="Male" />
                                <FormControlLabel disabled={isReadOnly} value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.FATHER_RESIDENCE}
                            id="outlined-basic" label="Father's Residence" value={addressState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.FATHER_RESIDENCE) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.HOUSE_PHONE}
                            id="outlined-basic" label="House Phone No." value={housePhoneState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.HOUSE_PHONE) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>

                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl sx={{ m: 0, minWidth: 200 }}>
                            <InputLabel id="religion-label">Religion</InputLabel>
                            <Select
                                required={true}
                                labelId="religion-label"
                                id="religion_select"
                                value={religionState}
                                label="Age"
                                error={isInvalidState == FormControlEnum.RELIGION}
                                onChange={(e) => { onInputChange(e.target.value, FormControlEnum.RELIGION) }}
                                disabled={isReadOnly}
                            >
                                <MenuItem value={'Hindu'}>Hindu</MenuItem>
                                <MenuItem value={'Jains'}>Jains</MenuItem>
                                <MenuItem value={'Sikhs'}>Sikhs</MenuItem>
                                <MenuItem value={'Buddhists'}>Buddhists</MenuItem>
                                <MenuItem value={'Christians'}>Christians</MenuItem>
                                <MenuItem value={'Muslims'}>Muslims</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 0, minWidth: 200 }} style={marginLeft}>
                            <InputLabel id="category-label">Category</InputLabel>
                            <Select
                                required={true}
                                labelId="category-label"
                                id="category-select"
                                value={categoryState}
                                label="Age"
                                disabled={isReadOnly}
                                error={isInvalidState == FormControlEnum.CATEGORY}
                                onChange={(e) => { onInputChange(e.target.value, FormControlEnum.CATEGORY) }}
                            >
                                <MenuItem value={'General'}>General</MenuItem>
                                <MenuItem value={'OBC'}>OBC</MenuItem>
                                <MenuItem value={'SC'}>SC</MenuItem>
                                <MenuItem value={'ST'}>ST</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.DOB}
                            id="outlined-basic" label="D.O.B." value={dobState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.DOB) }}
                            onFocus={(e) => { onDateFocus(FormControlEnum.DOB) }} type={isFocusOnDate == FormControlEnum.DOB ? 'date' : 'text'}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.DOJ}
                            id="outlined-basic" label="D.O.J." value={dojState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.DOJ) }}
                            onFocus={(e) => { onDateFocus(FormControlEnum.DOJ) }} type={isFocusOnDate == FormControlEnum.DOJ ? 'date' : 'text'}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField
                            id="outlined-basic" label="Last Institute" value={lastInstituteState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.LAST_INSTITUTE) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl fullWidth>
                            <InputLabel id="class-label">Class</InputLabel>
                            <Select
                                labelId="class-label"
                                id="class_select"
                                value={classState}
                                label="Age"
                                error={isInvalidState == FormControlEnum.CLASS_NAME}
                                disabled={isReadOnly}
                                onChange={(e) => { onInputChange(e.target.value, FormControlEnum.CLASS_NAME) }}
                            >
                                {classList.map((cls) => (<MenuItem key={'class_' + cls.name} value={cls.id}>{cls.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField
                            id="outlined-basic" label="Name of Public Exami. Passed" value={passedExamState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.EXAM_PASSED) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={6} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField
                            id="outlined-basic" label="Public Exami. Division" value={passedExamDivisionState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.PASSED_DEVISION) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={6} md={3} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField
                            id="outlined-basic" label="Public Exami. Year" value={passedExamYearState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.PASSED_YEAR) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField
                            id="outlined-basic" label="Name of Public Exami. Subject" value={passedExamSubjectState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.EXAM_SUBJECT) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>
                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <FormControl>
                            <FormLabel id="transport-facility-label">Transport Facility</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="transport-facility-label"
                                name="transport-facility"
                                value={transportFacilityState}
                                onChange={(e) => { onInputChange(e.target.value, FormControlEnum.TRANSPORT) }}
                            >
                                <FormControlLabel disabled={isReadOnly} value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel disabled={isReadOnly} value={false} control={<Radio />} label="No" />

                            </RadioGroup>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={4} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start", padding: "10px 0px 10px 0p" }}>
                        <TextField required={true} error={isInvalidState == FormControlEnum.ADHAR}
                            id="outlined-basic" label="Adhar No." value={adharState}
                            onChange={(e) => { onInputChange(e.target.value, FormControlEnum.ADHAR) }}
                            disabled={isReadOnly}
                            variant="outlined" style={textFieldStyle} />

                    </Grid>

                    <Grid item xs={12} md={12} columnSpacing={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={2}>
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                disabled={isReadOnly}
                                onClick={onSubmit}
                            >
                                {student ? 'Update Student' : 'Add Student'}
                            </LoadingButton>
                            {/* <Button variant="contained" disabled={isReadOnly} onClick={onSubmit}>{student ? 'Update Student' : 'Add Student'}</Button> */}
                            <Button variant="outlined" color="secondary" disabled={!!student} >Cancel</Button>
                        </Stack>
                    </Grid>

                </Grid>
            </Paper >
        </Grid >
    )
}